import React, { useState, useEffect } from 'react';
import { Chart, RadialLinearScale, ArcElement, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import StackedBarChart from './StackedBarChart'
// Register the components
Chart.register(RadialLinearScale, ArcElement, PointElement, LineElement, Filler, Tooltip, Legend);

function OverallRadarChart({ playerData }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [chartInstance, setChartInstance] = useState(null);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            // This will check if the chart instance exists and destroy it to avoid memory leaks
            if (chartInstance) {
                chartInstance.destroy();
            }
        };
    }, [chartInstance]);

    let ratingsValues = [];
    let usedLabels = [];

    const skaterRatings = playerData["Overall rating"]?.["Category Ratings"];

    if (skaterRatings && ['C', 'LW', 'RW', 'D'].includes(playerData['Position'])) {
        ratingsValues = [
            skaterRatings["SHT"],
            skaterRatings["PSS"],
            skaterRatings["HND"],
            skaterRatings["PHS"],
            skaterRatings["DEF"],
        ].map(rating => rating || 0); // Ensure undefined ratings default to 0

        usedLabels = ['SHT', 'PSS', 'HND', 'PHS', 'DEF'];
    } else if (skaterRatings) {
        ratingsValues = [
            skaterRatings["REF"],
            skaterRatings["CON"],
            skaterRatings["CLU"],
        ].map(rating => rating || 0);
        usedLabels = ['REF', 'CON', 'CLU'];
    }

    const datasets = [
        {
            label: 'Overall rating (Career)',
            data: ratingsValues,
            fill: true,
            backgroundColor: 'rgba(25, 118, 210, 0.6)', // Adjusted for some transparency
            borderColor: '#1a7bb8',
            pointBackgroundColor: 'white',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#1a7bb8',
            pointHoverBorderColor: '#fff',
            pointRadius: 6,
            pointHoverRadius: 5,
        }
    ];

    const data = {
        labels: usedLabels,
        datasets: datasets
    };

    const minRating = Math.min(...ratingsValues);
    const maxRating = 99;

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    title: function (context) {
                        let label = context[0].label;
                        if (context[0].parsed !== null) {
                            label += ': ' + context[0].parsed.r;
                        }
                        return label;
                    },
                    label: function () {
                        return '';
                    },
                },
                titleFont: { size: 16 }, // Set tooltip title font size
            },
            legend: {
                display: true,
            },
        },
        elements: {
            line: {
                borderWidth: 5,
                borderColor: 'white',
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: false,
                },
                ticks: {
                    display: true,
                    stepSize: 10,
                    font: {
                        size: 14,
                        family: 'Helvetica',
                        style: 'normal',
                        weight: '600',
                    },
                    color: '#fff',
                    backdropColor: 'transparent'
                },
                grid: {
                    lineWidth: 0.2,
                    color: '#ddd',
                },
                pointLabels: {
                    padding: 2,
                    font: {
                        size: 14,
                        family: 'Helvetica',
                        style: 'normal',
                        weight: '600',
                    },
                    color: '#fff',
                },
                min: minRating,
                max: maxRating,
            },
        },
    };

    return (
        <Box sx={{ marginBottom: windowWidth < 600 ? 8 : windowWidth < 900 ? 3 : 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: windowWidth < 600 ? 5 : windowWidth < 900 ? 2 : 2, justifyContent: 'space-between' }}>
                <Typography variant="h5" sx={{ color: 'white', display: 'flex', width: 'fit-content', marginTop: 2 }}>Overall ratings</Typography>
            </Box>

            <div>
                <Radar data={data} options={options} style={{ width: windowWidth < 600 ? windowWidth : windowWidth < 900 ? '400px' : '400px', height: windowWidth < 600 ? windowWidth : windowWidth < 900 ? '400px' : '400px' }} />
            </div>
            <StackedBarChart playerData={playerData}/>

        </Box>
    );
}

export default OverallRadarChart;
