import React, { useEffect, useState, useMemo } from 'react';
import { useLoading } from '../Contexts/LoadingContext';
import Autocomplete from '@mui/material/Autocomplete';
import { PropagateLoader } from 'react-spinners';
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, TablePagination, TextField } from '@mui/material';

function SkaterLeaderboard({ league }) {
    useEffect(() => {
        document.title = `Chel stats | ${league.toLocaleUpperCase()} Skater Leaderboard`;
    }, [league]);

    const [sheetData, setSheetData] = useState([]);
    const { loading, setLoading } = useLoading();
    const [sortConfig, setSortConfig] = useState({
        key: 'Points', // Default sorting column
        direction: 'desc',
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [usernameFilter, setUsernameFilter] = useState(null);
    const [positionFilter, setPositionFilter] = useState(null);

    // Fetch the data and handle loading states
    useEffect(() => {
        setLoading(true);
        const fetchSheetData = async () => {
            try {
                const response = await fetch(`/api/fetch/leaderboard?league=${league}&type=skater`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data = await response.json();
                setSheetData(data.values); // Ensure you get both the header and body rows here
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
                setLoading(false);
            }
        };

        fetchSheetData();
    }, [league]);

    const usernameOptions = useMemo(() => {
        const usernames = new Set(sheetData.slice(1).map(row => row[sheetData[0].indexOf('Username')]));
        return Array.from(usernames);
    }, [sheetData]);

    const positionOptions = useMemo(() => {
        const positions = new Set(sheetData.slice(1).map(row => row[sheetData[0].indexOf('Position')]));
        return Array.from(positions);
    }, [sheetData]);

    const visibleColumns = useMemo(() => {
        if (sheetData.length === 0) return [];
        // Make sure to get the correct header row, assuming it's the first row (sheetData[0])
        const headerRow = sheetData[0];
        const startColumnIndex = headerRow.indexOf('Username'); // Start at BE column
        const endColumnIndex = headerRow.indexOf('WAR');   // End at CZ column

        // Add Rank column at the start
        return [
            { key: 'rank', label: 'Rank', sortable: false },
            ...headerRow.slice(startColumnIndex, endColumnIndex + 1).map(column => ({
                key: column,
                sortable: true
            }))
        ];
    }, [sheetData]);

    const handleSort = (key) => {
        const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
        setSortConfig({ key, direction: isAsc ? 'desc' : 'asc' });
    };

    const filteredData = useMemo(() => {
        if (!usernameFilter && !positionFilter) {
            return sheetData.slice(1).filter(row => row[sheetData[0].indexOf('Username')]); // Exclude rows without a username
        }
    
        return sheetData.slice(1)
            .filter(row => row[sheetData[0].indexOf('Username')]) // Exclude rows without a username
            .filter(row => {
                const usernameMatch = usernameFilter
                    ? row[sheetData[0].indexOf('Username')] === usernameFilter
                    : true;
                const positionMatch = positionFilter
                    ? row[sheetData[0].indexOf('Position')] === positionFilter
                    : true;
                return usernameMatch && positionMatch;
            })
            .map(row => {
                // Alter position values to acronyms
                const positionIndex = sheetData[0].indexOf('Position');
                const newRow = [...row];
                switch (newRow[positionIndex]) {
                    case 'center':
                        newRow[positionIndex] = 'C';
                        break;
                    case 'leftWing':
                        newRow[positionIndex] = 'LW';
                        break;
                    case 'rightWing':
                        newRow[positionIndex] = 'RW';
                        break;
                    case 'defenseMen':
                        newRow[positionIndex] = 'D';
                        break;
                    default:
                        break;
                }
                return newRow;
            });
    }, [sheetData, usernameFilter, positionFilter]);
    

    const sortedData = useMemo(() => {
        if (filteredData.length === 0) return [];

        const columnIndex = sheetData[0].indexOf(sortConfig.key);
        if (columnIndex > -1) {
            return [...filteredData].sort((a, b) => {
                const aValue = isNaN(+a[columnIndex]) ? a[columnIndex] : +a[columnIndex];
                const bValue = isNaN(+b[columnIndex]) ? b[columnIndex] : +b[columnIndex];

                if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return filteredData;
    }, [filteredData, sheetData, sortConfig]);

    if (loading) {
        return <PropagateLoader style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }} color="#3498db" />;
    }

    if (!sheetData.length) return null;

    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ maxWidth: '95%', m: 'auto', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ m: 'auto', marginTop: 4, color: 'white' }}>Skater Leaderboard</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Autocomplete
                    options={usernameOptions}
                    getOptionLabel={(option) => option ? option.toString() : ''}
                    value={usernameFilter}
                    onChange={(event, newValue) => {
                        setUsernameFilter(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Filter by Username" variant="outlined" />}
                    sx={{
                        marginTop: 2, marginBottom: 0.5, width: 200, input: { color: 'white' }, // Text color
                        label: { color: 'gray' }, // Label color when not focused
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: 'gray' }, // Border color
                            '&:hover fieldset': { borderColor: 'white' }, // Border color on hover
                            '&.Mui-focused fieldset': { borderColor: 'gray' }, // Border color when focused
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' }
                    }}
                    freeSolo // Allows arbitrary input not limited to the options
                    componentsProps={{
                        clearIndicator: {
                            style: {
                                color: 'gray', // Change to your desired color
                            },
                        },
                    }}
                />
                <Autocomplete
                    options={positionOptions}
                    getOptionLabel={(option) => option ? option.toString() : ''}
                    value={positionFilter}
                    onChange={(event, newValue) => {
                        setPositionFilter(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Filter by Position" variant="outlined" />}
                    sx={{
                        marginTop: 2, marginBottom: 0.5, width: 200, input: { color: 'white' }, // Text color
                        label: { color: 'gray' }, // Label color when not focused
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: 'gray' }, // Border color
                            '&:hover fieldset': { borderColor: 'white' }, // Border color on hover
                            '&.Mui-focused fieldset': { borderColor: 'gray' }, // Border color when focused
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' }
                    }}
                    freeSolo // Allows arbitrary input not limited to the options
                    componentsProps={{
                        clearIndicator: {
                            style: {
                                color: 'gray', // Change to your desired color
                            },
                        },
                    }}
                />
            </Box>
            <TableContainer component={Paper} sx={{ marginTop: 2, position: 'relative', overflowX: 'auto' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1C4587' }}>
                            {visibleColumns.map((column, columnIndex) => {
                                const isSortedColumn = sortConfig.key === column.key;
                                return (
                                    <TableCell
                                        key={column.key}
                                        sx={{
                                            backgroundColor: isSortedColumn ? '#a9c4e8' : '#1C4587', // Light blue highlight for sorted column
                                            color: 'white',
                                            p: '6px',
                                            paddingX: '10px',
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            backgroundColor: '#1C4587',
                                            position: columnIndex === 1 ? 'sticky' : 'static',
                                            left: columnIndex === 1 ? 1 : 'auto', // Position sticky only on the first column
                                            zIndex: columnIndex === 1 ? 2 : 'auto', // Add column grid lines
                                        }}
                                    >
                                        {column.sortable ? (
                                            <TableSortLabel
                                                active={isSortedColumn}
                                                direction={isSortedColumn ? sortConfig.direction : 'asc'}
                                                hideSortIcon={!isSortedColumn}
                                                onClick={() => handleSort(column.key)}
                                            >
                                                {column.label || column.key}
                                            </TableSortLabel>
                                        ) : (
                                            column.label || column.key
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                            <TableRow key={rowIndex} sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}> {/* Add row grid lines */}
                                {visibleColumns.map((column, colIndex) => {
                                    const isSortedColumn = sortConfig.key === column.key;
                                    let cellValue;

                                    // Conditional logic to alter the text of the cell
                                    if (column.key === 'rank') {
                                        cellValue = rowIndex + 1 + page * rowsPerPage; // For rank column
                                    } else if (column.key === 'Points' && row[sheetData[0].indexOf(column.key)] > 100) {
                                        cellValue = `🔥 ${row[sheetData[0].indexOf(column.key)]} (High Points)`; // Example for Points column
                                    } else if (column.key === 'Position') {
                                        if (row[sheetData[0].indexOf(column.key)] === 'center') {
                                            cellValue = 'C';
                                        } else if (row[sheetData[0].indexOf(column.key)] === 'leftWing') {
                                            cellValue = 'LW';
                                        } else if (row[sheetData[0].indexOf(column.key)] === 'rightWing') {
                                            cellValue = 'RW';
                                        } else if (row[sheetData[0].indexOf(column.key)] === 'defenseMen') {
                                            cellValue = 'D';
                                        }

                                    } else if (column.key === 'Team') {
                                        const teamId = row[sheetData[0].indexOf(column.key)];
                                        cellValue = (
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                
                                            </Box>
                                        );
                                    } else {
                                        cellValue = row[sheetData[0].indexOf(column.key)]; // Default case for other columns
                                    }

                                    return (
                                        <TableCell
                                            key={colIndex}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                textAlign: 'left',
                                                fontWeight: '500',
                                                fontSize: '12px',
                                                padding: '8px',
                                                backgroundColor: isSortedColumn ? '#a9c4e8' : colIndex == 1 ? 'white' : 'inherit', // Highlight sorted column
                                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                position: colIndex === 1 ? 'sticky' : 'static',
                                                left: colIndex === 1 ? 1  : 'auto', // Position sticky only on the first column
                                                zIndex: colIndex === 1 ? 1 : 'auto', // Add column grid lines
                                            }}
                                        >
                                            {cellValue}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
                
            </TableContainer>
            <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', width: '100%', zIndex: 1 }}>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={sortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{zIndex: 5}}
                />
            </Box>
        </Box>
    );
}

export default SkaterLeaderboard;
