import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Modal, TextField, Alert } from '@mui/material';
import { useUser } from '../Contexts/UserContext';
import { refreshUserData } from '../EASHL/Players/PlayerStats';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FaDiscord } from 'react-icons/fa';
import SavedSearches from './SavedSearches';
import SearchAndDropdown from './SearchAndDropdown'
import SearchAndDropdownClubs from './SearchAndDropdownClubs';
import CustomAccordion from './CustomAccordion'
import ClaimedProfile from './ClaimedProfile'
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function HomePage() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  // Modal state
  const [open, setOpen] = useState(false);
  const [isLoginMode, setIsLoginMode] = useState(true); // Toggle between login and register
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState(''); // Only for registration
  const [errorMessage, setErrorMessage] = useState('');
  const [usernameSearch, setUsernameSearch] = useState('');
  const [teamname, setTeamName] = useState('');
  const [teamnameSearch, setTeamNameSearch] = useState('');

  const [refreshKey, setRefreshKey] = useState(0);
  const [consolegen, setConsolegen] = useState('');

  // Fetch user data on component mount
  useEffect(() => {
    refreshUserData(setUser);
  }, [setUser]);

  useEffect(() => {
    document.title = `Chel stats | Home`;
  }, []);

  // Handlers for navigating to player and club stats
  const handlePlayerClick = (username, teamname, consolegen) => {
    navigate(`/players?username=${username}&teamname=${teamname}&console=common-gen5`);
  };

  const handleClubClick = (teamname) => {
    navigate(`/clubs?teamname=${teamname}&console=common-gen5`);
  };

  const handleExploreAboutPage = () => {
    navigate(`/about`);
  };

  // Login and registration handlers
  const handleLogin = async () => {
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
        setOpen(false);  // Close modal after login
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      setErrorMessage('Login failed. Please try again.');
      console.error('Error logging in:', error);
    }
  };

  const handleRegister = async () => {
    try {
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
        setOpen(false);  // Close modal after registration
      } else {
        const data = await response.json();
        setErrorMessage(data.message);  // Set the error message from the backend
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
      console.error('Registration error:', error);
    }
  };



  // Define the welcome message
  const renderWelcomeMessage = () => {
    if (!user) {
      return (
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
          Welcome to Chel Stats!
        </Typography>
      );
    } else {
      return (
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
          Welcome back, {user.username}!
        </Typography>
      );
    }
  };

  // Define the claimed profile section
  const renderClaimedProfileSection = () => {
    if (!user) {
      return (
        <Box>
          <CustomAccordion title={'Player Search'} children={<SearchAndDropdown
            username={usernameSearch}
            setUsername={setUsernameSearch}
            teamname={teamnameSearch}
            setTeamName={setTeamNameSearch}
            refreshKey={refreshKey}
            setRefreshKey={setRefreshKey}
          />} isExpandedBool={true}/>
          <CustomAccordion title={'Club Search'} children={<SearchAndDropdownClubs
            teamname={teamnameSearch}
            setTeamName={setTeamNameSearch}
            setConsolegen={setConsolegen}
            refreshKey={refreshKey}
            setRefreshKey={setRefreshKey}
          />} isExpandedBool={true}/>
        </Box>

      );
    } else if (user.claimedProfile) {
      const { username, teamname, consolegen } = user.claimedProfile;
      return (
        <Box>
          <CustomAccordion title={'Claimed Profiles'} children={<ClaimedProfile username={username} teamname={teamname} />} isExpandedBool={true}/>

          <CustomAccordion title={'Player Search'} children={<SearchAndDropdown
            username={usernameSearch}
            setUsername={setUsernameSearch}
            teamname={teamnameSearch}
            setTeamName={setTeamNameSearch}
            refreshKey={refreshKey}
            setRefreshKey={setRefreshKey}
          />} isExpandedBool={false} />
          <CustomAccordion title={'Club Search'} children={<SearchAndDropdownClubs
            teamname={teamnameSearch}
            setTeamName={setTeamNameSearch}
            setConsolegen={setConsolegen}
            refreshKey={refreshKey}
            setRefreshKey={setRefreshKey}
          />} isExpandedBool={false} />

        </Box>
      );
    } else {
      return (
        <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px', padding: 2 }}>
          <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
            Claimed profile
          </Typography>
          <Typography variant="body1" color="white" fontWeight="300">
            You don’t have a claimed profile yet.
          </Typography>
        </Box>
      );
    }
  };

  return (
    <Container>
      <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '40px' }}>
        {renderWelcomeMessage()}
      </Box>

      {renderClaimedProfileSection()}

      <Modal
        open={open}
        onClose={() => setOpen(false)}  // Close the modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isLoginMode ? 'Login' : 'Register'}
          </Typography>
          <Box>
            {!isLoginMode && (
              <TextField
                fullWidth
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
              />
            )}
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              onClick={isLoginMode ? handleLogin : handleRegister}
            >
              {isLoginMode ? 'Login' : 'Register'}
            </Button>
            <Button
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => setIsLoginMode(!isLoginMode)}
            >
              {isLoginMode ? "Don't have an account? Register" : "Already have an account? Login"}
            </Button>
            {errorMessage && <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>}
          </Box>
        </Box>
      </Modal>
      <Box my={2} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px', padding: 2 }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
          Join the Chel stats discord server!
        </Typography>
        <Typography variant="body1" color="white" fontWeight="300" sx={{ marginBottom: 4 }}>
          Including: LFG channels, tournaments, and the Chel stats discord bot for viewing stats in discord!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1, width: 'fit-content' }}
          href="https://discord.gg/GHx7Zvwk2P"
        >
          <FaDiscord style={{ marginRight: '4px', width: '25px', height: '25px' }} />
          Discord
        </Button>
      </Box>

      <Box my={2} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px', padding: 2 }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
          Explore Features
        </Typography>
        <Typography variant="body1" color="white" fontWeight="300" sx={{ marginBottom: 4 }}>
          Learn all about what Chel stats has to offer!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1 }}
          onClick={handleExploreAboutPage}
        >
          About page
        </Button>
      </Box>
    </Container>
  );
}

export default HomePage;
