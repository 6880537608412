import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider, Link } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
import { FaDiscord } from 'react-icons/fa';
import GameCarousel from '../GameCarousel';
import { CircularProgress } from '@mui/material';

function PGL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [gameData, setGameData] = useState();
    const [gamesLoading, setGamesLoading] = useState(true);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        document.title = `Chel stats | PGL`;
    }, []);

    useEffect(() => {
        const fetchGameData = async () => {
            setGamesLoading(true)
            try {
                const response = await fetch(`/api/gamedata/PGL`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data = await response.json();
                setGameData(data)
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
            } finally {
                setGamesLoading(false)
            }
        };

        fetchGameData();
    }, []);


    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    return (
        <Container>
            <Box
                sx={{
                    minHeight: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {gamesLoading ? (
                    <CircularProgress color="primary" />
                ) : gameData ? (
                    <GameCarousel gamesData={gameData} league={'PGL'} />
                ) : (
                    <Typography color="white">No games available</Typography>
                )}
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '30px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                    <Typography variant="h6" color="white" fontWeight="800" gutterBottom>
                        Welcome to the PGL!
                    </Typography>
                </Box>
                <Typography variant="body1" color="white" paragraph sx={{ marginTop: 3 }}>
                    PGL is one of the fastest growing communities in chel! We are an open and positive environment for all skill levels to come in and compete or continue to improve at the game. We have our own media team that casts the games live, does pregame shows, and does live community updates! We encourage everyone to come in a support and watch some of your future teammates play!
                </Typography>
                <Typography variant="subtitle1" color="white" fontWeight="300" gutterBottom>
                    Join our Discord server!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    href="https://discord.gg/premiergaming"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        marginTop: 1,
                        backgroundColor: '#5865F2', // Discord's branding color
                        '&:hover': {
                            backgroundColor: '#4e5cc8' // Slightly darker shade for hover effect
                        }
                    }}
                >
                    <FaDiscord style={{ marginRight: '10px', width: '25px', height: '25px' }} />
                    Join Discord
                </Button>

            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h6" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: .2, marginTop: 1 }}>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for PGL!
                </Typography>
                <Button variant="contained" color="primary" href="/PGL/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/PGL/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/PGL/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard
                </Button>
                {/* <Button variant="contained" color="primary" href="/PGL/skater-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Skater Leaderboard Playoffs
                </Button>
                <Button variant="contained" color="primary" href="/PGL/goalie-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard Playoffs
                </Button> */}
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h6" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: 2, marginTop: 2 }}>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph sx={{ marginLeft: 2 }}>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'arctic-invaders'} text={'Arctic Invaders'} league={'PGL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'dolphins'} text={'Dolphins'} league={'PGL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'greyhounds'} text={'Greyhounds'} league={'PGL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'huskies'} text={'Huskies'} league={'PGL'} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'ice-storm'} text={'Ice Storm'} league={'PGL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'newport-nukes'} text={'Newport Nukes'} league={'PGL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'party-mix'} text={'Party Mix'} league={'PGL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'slapshot-society'} text={'Slapshot Society'} league={'PGL'} />

                    </Container>
                </Container>
            </Box>
        </Container>
    );
}



export default PGL_Home;