import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Tab, Tabs, Typography, Divider, Paper, Button } from '@mui/material';
import { useLoading } from '../Contexts/LoadingContext';
import { useLocation } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import ScoringTimelineDisplay from './ScoringTimelineDisplay'
import ThreeStarsDisplay from './ThreeStarsDisplay'
import GameStats from './GameStats'
import GamePlayerStats from './GamePlayerStats'
import cloneDeep from 'lodash.clonedeep';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
            style={{ height: '100%', overflow: 'auto', width: '100%', minHeight: '200px' }} // You might merge this with `...other` if passing styles via props
        >
            {value === index && (
                <Box sx={{ marginTop: 4 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function GameRecord() {
    const { league, matchId } = useParams();
    const [value, setValue] = React.useState('recap');
    const [gameData, setGameData] = useState();
    const [teamID1, setTeamID1] = useState('');
    const [teamID2, setTeamID2] = useState('');
    const [team1Score, setTeam1Score] = useState('');
    const [team2Score, setTeam2Score] = useState('');
    const [winningTeamId, setWinningTeamId] = useState('');
    const [gameDate, setGameDate] = useState('');
    const dataFetched = useRef(false);
    const storyFetched = useRef(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const dataFetchedRef = useRef(false);
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const [selectedGame, setSelectedGame] = useState(null);
    const [generatedStory, setGeneratedStory] = useState('');
    const [gameObject, setGameObject] = useState('');

    const [scoringTimeline, setScoringTimeline] = useState('');
    const [threestars, setThreeStars] = useState('');

    const { loading, setLoading } = useLoading();
    const [ storyLoading, setStoryLoading ] = useState(true);

    const [gameType, setGameType] = useState(''); // default value
    const [imageUrls1, setImageUrls1] = useState('');
    const [imageUrls2, setImageUrls2] = useState('');

    const [currentImage1, setCurrentImage1] = useState(imageUrls1[0]);
    const [currentImage2, setCurrentImage2] = useState(imageUrls2[0]);

    const [color1, setColor1] = useState(null);
    const [color2, setColor2] = useState(null);

    const getDominantColor = (imageSrc, callback) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = imageSrc;

        img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

            const colorCounts = {};
            const colors = [];

            for (let i = 0; i < imageData.length; i += 4) {
                const r = imageData[i];
                const g = imageData[i + 1];
                const b = imageData[i + 2];
                const color = `${r},${g},${b}`;

                // Ignore completely black, white, and very dark/light colors
                const isBlandColor =
                    (r < 30 && g < 30 && b < 30) || // Very dark colors
                    (r > 225 && g > 225 && b > 225); // Very light colors

                if (!isBlandColor) {
                    colorCounts[color] = (colorCounts[color] || 0) + 1;
                }
            }

            // Sort colors by frequency
            const sortedColors = Object.entries(colorCounts)
                .sort(([, countA], [, countB]) => countB - countA)
                .map(([color]) => color);

            // Helper to calculate RGB distance
            const colorDistance = (color1, color2) => {
                const [r1, g1, b1] = color1.split(',').map(Number);
                const [r2, g2, b2] = color2.split(',').map(Number);
                return Math.sqrt((r1 - r2) ** 2 + (g1 - g2) ** 2 + (b1 - b2) ** 2);
            };

            // Find the most dominant and a distinct secondary color
            let dominantColor = sortedColors[0];
            let secondaryColor = sortedColors[1];

            // Ensure the secondary color is distinct enough
            for (let i = 1; i < sortedColors.length; i++) {
                if (colorDistance(dominantColor, sortedColors[i]) > 50) { // Adjust threshold as needed
                    secondaryColor = sortedColors[i];
                    break;
                }
            }

            // Fallback to default if no valid colors found
            dominantColor = dominantColor || "128,128,128"; // Default to gray
            secondaryColor = secondaryColor || "128,128,128"; // Default to gray

            callback([`rgb(${dominantColor})`, `rgb(${secondaryColor})`]);
        };
    };


    useEffect(() => {
        if (!gameData) return
        // Replace 'image1Src' and 'image2Src' with your image sources
        getDominantColor(loadLogo(gameData.game_stats.team1_name, league), (color) => setColor1(color));
        getDominantColor(loadLogo(gameData.game_stats.team2_name, league), (color) => setColor2(color));
    }, [gameData]);

    const highlightStyle = {
        fontWeight: 'bold',
        color: 'white',
        fontSize: '32px',
        marginX: 3
    };
    const notHighlightStyle = {
        color: 'white', marginRight: 3, fontSize: '32px', marginX: 3
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleImageError = () => {
        const nextIndex = imageUrls1.indexOf(currentImage1) + 1;
        if (nextIndex < imageUrls1.length) {
            setCurrentImage1(imageUrls1[nextIndex]);
        }
    };
    const handleImageError2 = () => {
        const nextIndex = imageUrls2.indexOf(currentImage2) + 1;
        if (nextIndex < imageUrls2.length) {
            setCurrentImage2(imageUrls2[nextIndex]);
        }
    };
    useEffect(() => {
        if (gameData) {
            document.title = `Chel stats | ${league} | ${gameData.matchId}`;
        }
    }, []);

    useEffect(() => {
        const fetchGameData = async () => {

            if (dataFetchedRef.current) return;

            let apiUrl = `/api/gamedata/${league}`;

            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const game = data[matchId]
                setGameData(game);

                const gameObjectPrepared = await prepareGameObject(game);
                setGameObject(gameObjectPrepared); // Set gameObject in state

                const timeline = await simulateScoringTimeline(gameObjectPrepared); // Use the newly prepared gameObject
                setScoringTimeline(timeline); // Set the scoring timeline in state

                const threestars = await calculateThreeStars(gameObjectPrepared)
                setThreeStars(threestars)

                const gameData = await calculateGameStats(game)
                setGameData(gameData)

                dataFetchedRef.current = true;

            } catch (error) {
                console.error('Failed to fetch club data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchGameData();

    }, [location.search]);

    function adjustToEST(dateTimeString) {
        // Parse the date string
        const date = new Date(dateTimeString);

        // Subtract 3 hours (in milliseconds)
        date.setHours(date.getHours() - 5);

        // Format back to 'MM/DD/YYYY HH:mm:ss EST'
        const formattedDate = date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        return `${formattedDate} EST`;
    }

    const prepareGameObject = async (gameData) => {
        if (!gameData || !gameData.game) return null;
        try {
            let gameObject = {}
            let gameToChange = gameData
            const id1 = gameData.game['Team 1 ID'];
            setTeamID1(id1)
            const id2 = gameData.game['Team 2 ID']
            setTeamID2(id2)
            let team1 = removeLeaguePrefix(gameData.game['Team 1']);
            let team2 = removeLeaguePrefix(gameData.game['Team 2']);
            let endedIn = gameData.game['OT'] === "no" ? "Regulation" : "Overtime";

            gameObject[team1] = {}
            gameObject[team2] = {}
            gameObject[team1]['teamID'] = id1
            gameObject[team2]['teamID'] = id2
            gameObject[team1]['Score'] = gameData.game['Team 1 Score'];
            gameObject[team2]['Score'] = gameData.game['Team 2 Score'];
            gameObject[team1]['player_stats'] = {}
            gameObject[team2]['player_stats'] = {}


            for (const teamID of [id1, id2]) {
                const teamKey = teamID === id1 ? team1 : team2;
                for (const [playerId, playerData] of Object.entries(gameToChange.skaters)) {
                    if (playerData['Team ID'] === teamID) {
                        let PlayerName = playerData.Username;
                        gameObject[teamKey]['player_stats'][PlayerName] = playerData
                    }
                }

                for (const [playerId, playerData] of Object.entries(gameToChange.goalies)) {
                    if (playerData['Team ID'] === teamID) {
                        let PlayerName = playerData.Username;
                        gameObject[teamKey]['player_stats'][PlayerName] = playerData
                    }
                }
            }

            gameObject['endedIn'] = endedIn

            setGameDate(adjustToEST(gameData.game.Date))

            return gameObject
        } catch (error) {
            console.error('Error generating the game:', error);
        }
    };

    const simulateScoringTimeline = async (gameObject) => {
        if (!gameObject) return null;

        const generateTimestampAndPeriod = (isOvertime) => {
            const minute = Math.floor(Math.random() * (isOvertime ? 5 : 20)).toString().padStart(2, '0');
            const second = Math.floor(Math.random() * 60).toString().padStart(2, '0');
            const timestamp = `${minute}:${second}`;
            let periodNumber = isOvertime ? 'OT' : Math.ceil(Math.random() * 3).toString();
            return { timestamp, periodNumber };
        };

        let scoringTimeline = [];
        const clonedGameObject = cloneDeep(gameObject);  // Using lodash's cloneDeep to ensure a full deep clone

        const processTeam = (teamName, teamData, isOvertime) => {
            Object.entries(teamData.player_stats).forEach(([playerName, stats]) => {
                const goals = parseInt(stats['Goals']);
                const gwg = parseInt(stats['GWGs']);

                for (let i = 0; i < goals; i++) {
                    const { timestamp, periodNumber } = generateTimestampAndPeriod(isOvertime && gwg > 0 && i === goals - 1);

                    let assistPlayers = [];
                    Object.entries(teamData.player_stats).forEach(([assistPlayerName, assistStats]) => {
                        if (assistPlayerName !== playerName && parseInt(assistStats['Assists']) > 0 && assistPlayers.length < 2) {
                            assistPlayers.push(assistPlayerName);
                            assistStats['Assists'] = parseInt(assistStats['Assists']) - 1;
                        }
                    });

                    scoringTimeline.push({
                        timestamp,
                        periodNumber,
                        teamName,
                        scorer: playerName,
                        assists: assistPlayers,
                        teamID: stats['Team ID']
                    });

                    if (isOvertime && gwg > 0 && i === goals - 1) break;
                }
            });
        };

        // Process both teams
        Object.keys(clonedGameObject).filter(key => clonedGameObject[key].teamID).forEach(teamKey => {
            processTeam(getFirstThreeLetterstwo(teamKey), clonedGameObject[teamKey], clonedGameObject.endedIn === 'Overtime');
        });

        // Sort by period and then timestamp
        scoringTimeline.sort((a, b) => {
            if (a.periodNumber === 'OT' && b.periodNumber !== 'OT') return 1;
            if (b.periodNumber === 'OT' && a.periodNumber !== 'OT') return -1;
            if (a.periodNumber === b.periodNumber) return a.timestamp.localeCompare(b.timestamp);
            return parseInt(a.periodNumber) - parseInt(b.periodNumber);
        });

        return scoringTimeline;
    };

    const calculateThreeStars = async (gameObject) => {
        if (!gameObject) return null;
        let playerPerformances = [];

        // Define weights for different stats
        const weights = {
            'Goals': 3,    // Goals are weighted more heavily
            'Assists': 2,  // Assists have a moderate weight
            'Hits': .4,     // Hits are considered less important but still contribute
            'Takeaways': .4,
            'Interceptions': .4,
            'Offense Rating': .07,
            'Defense Rating': .07,
            'Teamplay Rating': .07,
            'GWGs': 1,
            'GAA': -10,
            'Saves': 2,
            'Save %': 15
            // Takeaways are considered equally to hits
            // Add more weights for other stats as needed
        };


        // Combine player stats from both teams
        Object.entries(gameObject).forEach(([teamName, teamData]) => {
            if (teamName === 'endedIn' || teamName === 'timestamp') return; // Skip non-team properties

            Object.entries(teamData.player_stats).forEach(([playerName, stats]) => {
                let performanceScore = 0;
                // Calculate weighted score
                Object.keys(stats).forEach(statKey => {
                    if (weights[statKey]) {
                        performanceScore += stats[statKey] * weights[statKey];
                    }
                });

                playerPerformances.push({
                    teamName,
                    playerName,
                    performanceScore,
                    ...stats // Include other stats for potential tie-breakers or detailed analysis
                });
            });
        });

        // Sort by performanceScore in descending order
        playerPerformances.sort((a, b) => b.performanceScore - a.performanceScore);

        // Return the top three players as the three stars of the game
        return playerPerformances.slice(0, 3).map((player, index) => {
            // Determine if the player is a skater or a goalie based on available stats
            const isSkater = 'Goals' in player && 'Assists' in player; // Check for skater stats
            const isGoalie = 'Saves' in player && 'Save %' in player;  // Check for goalie stats

            return {
                star: index + 1,
                playerName: player['Username'],
                teamName: player['teamName'],
                performanceScore: player.performanceScore,
                ...(isSkater && {
                    goals: player['Goals'],
                    assists: player['Assists'],
                    hits: player['Hits'],
                    takeaways: player['Takeaways'],
                    interceptions: player['Interceptions']
                }),
                ...(isGoalie && {
                    saves: player['Saves'],
                    savePercentage: player['Save %'],
                    gaa: player['GAA']
                }),
                // Add any additional stats you'd like to include
            };
        });

    };

    const calculateGameStats = async (gameData) => {
        const weights = {
            "Defense Rating": 0,
            "Offense Rating": 0,
            "Teamplay Rating": 0,
            "Pass %": .3,
            "Shots": 5,
            "Faceoff Wins": 1,
            "Possession (secs)": .01,
            "Shot Attempts": 1.5,
            "Takeaways": 1.5,
            "Interceptions": 1.5,
            "Passes": .35,
            "Goals": 30,
            "PIMs": -8,
            "Hits": .25
        };

        let teamScores = {};
        let team1 = gameData.game['Team 1']
        let team2 = gameData.game['Team 2'];
        let team1ID = gameData.game['Team 1 ID']
        let team2ID = gameData.game['Team 2 ID']
        teamScores["game1"] = {};

        const aggregate = {};

        aggregate[team1] = {};
        aggregate[team2] = {};

        Object.keys(weights).forEach(statKey => {
            aggregate[team1][statKey] = 0;
            aggregate[team2][statKey] = 0;
        });

        for (const teamID of [team1ID, team2ID]) {
            const teamKey = teamID === team1ID ? team1 : team2;

            // Filter players belonging to the current team
            const teamPlayers = Object.values(gameData.skaters).filter(player => player['Team ID'] === teamID);

            // Sum up stats for each player
            teamPlayers.forEach(playerStats => {
                Object.keys(weights).forEach(statKey => {
                    aggregate[teamKey][statKey] += parseFloat(playerStats[statKey] || 0); // Add player stats to the team's total
                });
            });

            // Divide by the number of players to get averages
            const playerCount = teamPlayers.length || 1; // Avoid division by zero
            Object.keys(weights).forEach(statKey => {
                if (['Pass %', 'Teamplay Rating', 'Offense Rating', 'Defense Rating'].includes(statKey)) {
                    let stat = aggregate[teamKey][statKey] /= playerCount
                    aggregate[teamKey][statKey] = stat.toFixed(2);
                }
            });
        }

        for (const teamId in aggregate) {
            let teamScore = Object.keys(weights).reduce((score, stat) => {
                return score + (aggregate[teamId][stat] ? parseFloat(aggregate[teamId][stat]) * weights[stat] : 0);
            }, 0);

            teamScores[teamId] = teamScore;
        }

        const team1GoalScore = gameData.game['Team 1 Score'];
        const team2GoalScore = gameData.game['Team 2 Score'];

        setWinningTeamId(
            team1GoalScore > team2GoalScore
                ? gameData.game['Team 1 ID']
                : team2GoalScore > team1GoalScore
                    ? gameData.game['Team 2 ID']
                    : null // Handle the case of a tie
        );

        const deservedWinnerId = Object.keys(teamScores).reduce((a, b) => teamScores[a] > teamScores[b] ? a : b);
        const deservedLoserId = Object.keys(teamScores).reduce((a, b) => teamScores[a] < teamScores[b] ? a : b);

        var totalScore = teamScores[deservedWinnerId] + teamScores[deservedLoserId];
        var team1Score = Math.min(Math.max(Math.round((teamScores[team1] / totalScore) * 100), 0), 100);
        var team2Score = Math.min(Math.max(Math.round((teamScores[team2] / totalScore) * 100), 0), 100);

        let deservedToWinString = "Evenly played match!";
        if (deservedWinnerId !== deservedLoserId) {
            if (team1Score > team2Score) {
                deservedToWinString = `Team ${team1} deserved to beat ${team2} with a score of ${team1Score}% to ${team2Score}%`;
            } else {
                deservedToWinString = `Team ${team2} deserved to beat ${team1} with a score of ${team2Score}% to ${team1Score}%`;
            }
        }

        // Construct the new game data
        let newGameData = {
            "deserved_winner": deservedWinnerId,
            "deserved_winner_score": teamScores[deservedWinnerId],
            "deserved_loser": deservedLoserId,
            "deserved_loser_score": teamScores[deservedLoserId],
            "deserved_to_win_string": deservedToWinString,
            "game_stats": {
                "team1": Object.fromEntries(Object.entries(weights).map(([key]) => [key, aggregate[team1][key]])),
                "team2": Object.fromEntries(Object.entries(weights).map(([key]) => [key, aggregate[team2][key]])),
                "team1_deserved_score": team1Score,
                "team2_deserved_score": team2Score,
                "team1_name": team1,
                "team2_name": team2,
                "team1_goals": gameData.game['Team 1 Score'],
                "team2_goals": gameData.game['Team 2 Score'],
                "team1_ID": team1ID,
                "team2_ID": team2ID
            }
        };

        return newGameData;
    }

    useEffect(() => {
        // This function should be defined within the useEffect or outside the component
        const fetchGameStory = async (game) => {
            if (gameObject) {
                setStoryLoading(true);
                try {
                    const response = await fetch('/api/generate-story', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ gameData: gameObject }),
                    });
                    const data = await response.json();
                    if (!response.ok) throw new Error('Failed to fetch the game story');
                    setGeneratedStory(data.story); // Adjust according to the actual response structure
                } catch (error) {
                    console.error('Error fetching the game story:', error);
                } finally {
                    setStoryLoading(false);
                }
            }
        };

        if (gameObject && !storyFetched.current) {
            fetchGameStory(gameObject);
            storyFetched.current = true;
        }
    
    }, [gameObject]);

    function removeLeaguePrefix(teamName) {
        // Remove the first word (assumed to be the league prefix)
        return teamName.split(' ').slice(1).join(' ');
    }

    function getFirstThreeLetters(str) {
        str = removeLeaguePrefix(str)
        if (str != undefined) {
            // Remove all spaces from the input string
            const noSpaces = str.replace(/\s+/g, '');

            // Return the first three characters of the resulting string
            return noSpaces.substring(0, 3);
        } else {
            return ""
        }
    }

    function getFirstThreeLetterstwo(str) {
        if (str != undefined) {
            // Remove all spaces from the input string
            const noSpaces = str.replace(/\s+/g, '');

            // Return the first three characters of the resulting string
            return noSpaces.substring(0, 3);
        } else {
            return ""
        }
    }

    function formatTeamName(teamName) {
        // Split the team name to remove the league prefix and join remaining parts
        const formattedName = teamName.split(' ').slice(1).join(' ');

        // Convert to lowercase and replace spaces with hyphens
        return formattedName.toLowerCase().replace(/\s+/g, '-');
    }

    const loadLogo = (teamName, league) => {
        try {
            return require(`./${league}/Logos/${formatTeamName(teamName)}.png`);
        } catch (error) {
            console.error("Error loading league logo:", error);
            return null;
        }
    };



    if (loading || !gameData || !gameObject) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}><PropagateLoader color="#3498db" /></div>;
    }

    if (gameData) {
        return (
            <Box sx={{ marginBottom: 5, width: windowWidth < 600 ? '95%' : windowWidth < 900 ? '60%' : '60%', marginX: 'auto' }}>
                {gameData && (
                    <>
                        <Typography variant="h5" sx={{ color: 'white', fontSize: 18, marginTop: 4, marginLeft: 4 }}>{gameDate}</Typography>
                        <Container sx={{ overflow: 'clip', position: 'relative', marginTop: 3, borderRadius: 10, backgroundColor: '#171717', boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '95%', padding: 0, height: '130px', marginX: 'auto' }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 1, overflow: 'clip', '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: -20,
                                    right: 0,
                                    bottom: 0,
                                    backgroundImage: `url(${loadLogo(gameData.game_stats.team1_name, league)})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'left',
                                    backgroundSize: '40%',
                                    opacity: 0.08,
                                    zIndex: 2,
                                }
                            }}>
                                <img src={currentImage1} onError={handleImageError} alt="" style={{ display: 'none' }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
                                    <Typography variant="h5" sx={{ color: 'white', fontSize: 22, fontWeight: 600 }}>{(getFirstThreeLetters(gameData.game_stats.team1_name) || "Team1").toUpperCase() || 'Team 1'}</Typography>
                                    <Typography variant="h5" sx={{ color: 'white', fontSize: 14 }}>SOG: {gameData.game_stats.team1.Shots || 0}</Typography>
                                </Box>
                                <Typography noWrap sx={teamID1 === winningTeamId ? highlightStyle : notHighlightStyle}>{gameData.game_stats.team1_goals || '0'}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                <Typography noWrap sx={{ color: 'white', fontSize: '18px' }}>FINAL</Typography>
                                <Typography noWrap sx={{ color: 'white', fontSize: '18px' }}>{gameObject.endedIn === "Regulation" ? "" : "OT"}</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 1, overflow: 'clip', '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: -20,
                                    bottom: 0,
                                    backgroundImage: `url(${loadLogo(gameData.game_stats.team2_name, league)})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'right',
                                    backgroundSize: '40%',
                                    opacity: 0.08,
                                    zIndex: 2,
                                }
                            }}>
                                <img src={currentImage2} onError={handleImageError2} alt="" style={{ display: 'none' }} />
                                <Typography noWrap sx={teamID2 === winningTeamId ? highlightStyle : notHighlightStyle}>{gameData.game_stats.team2_goals || '0'}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: 2 }}>
                                    <Typography variant="h5" sx={{ color: 'white', fontSize: 22, fontWeight: 600 }}>{(getFirstThreeLetters(gameData.game_stats.team2_name) || "Team2").toUpperCase() || 'Team 2'}</Typography>
                                    <Typography variant="h5" sx={{ color: 'white', fontSize: 14 }}>SOG: {gameData.game_stats.team2.Shots || 0}</Typography>
                                </Box>
                            </Box>
                        </Container>

                        <Container sx={{ marginTop: 3, borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="stats tabs" variant="scrollable">
                                <Tab key='recap' label='Recap' value='recap' sx={{ color: 'white' }} />
                                <Tab key='game stats' label='Game stats' value='game stats' sx={{ color: 'white' }} />
                                <Tab key='player stats' label='Player stats' value='player stats' sx={{ color: 'white' }} />
                            </Tabs>
                        </Container>
                        <TabPanel value={value} index='recap'>
                            <Paper
                                elevation={3}
                                sx={{
                                    padding: 2,
                                    color: 'white',
                                    marginTop: 2,
                                    margin: 1,
                                    backgroundColor: '#171717',
                                    boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)',
                                    border: 'solid #656565 .001rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    minHeight: '100px', // Ensure a minimum height while loading
                                }}
                            >
                                {storyLoading ? (
                                    <CircularProgress color="primary" />
                                ) : (
                                    <Typography>{generatedStory?.content}</Typography>
                                )}
                            </Paper>
                            <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 5, width: windowWidth < 600 ? '90%' : windowWidth < 900 ? '96%' : '96%', marginX: 'auto' }} />
                            {/* <AdComponent /> */}
                            <Box sx={{ marginTop: 3 }}>
                                <Typography sx={{ color: 'white', marginTop: 3, marginBottom: 2, marginLeft: 2, fontSize: '24px' }}>Scoring (simulated timeline)</Typography>
                                <ScoringTimelineDisplay scoringTimeline={scoringTimeline}
                                    teamID1={teamID1}
                                    teamID2={teamID2}
                                    imageUrls1={loadLogo(gameData.game_stats.team1_name, league)}
                                    imageUrls2={loadLogo(gameData.game_stats.team2_name, league)} />
                                <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 5, width: windowWidth < 600 ? '90%' : windowWidth < 900 ? '96%' : '96%', marginX: 'auto' }} />
                                <Typography sx={{ color: 'white', marginTop: 3, marginLeft: 2, marginBottom: 2, fontSize: '24px' }}>Three stars</Typography>
                                {/* <AdComponent slotID={"7688723993"} /> */}
                                <ThreeStarsDisplay stars={threestars} />
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index='game stats'>
                            {/* <AdComponent slotID={"7688723993"} /> */}
                            <GameStats gameData={gameData} color1={color1} color2={color2} />
                        </TabPanel>
                        <TabPanel value={value} index='player stats'>
                            <GamePlayerStats gameStats={gameObject} team1={gameData.game_stats.team1_name} team2={gameData.game_stats.team2_name} />
                            {/* <AdComponent slotID={"7688723993"} /> */}
                        </TabPanel>
                    </>
                )}
            </Box>
        );
    }
}

export default GameRecord;
