import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Tabs, Tab, Menu, MenuItem, Container } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';


const loadLogo = (league) => {
    try {
        return require(`./${league}/Logos/${league}_Logo.png`);
    } catch (error) {
        console.error("Error loading league logo:", error);
        return null;
    }
};

function Navbar({ leagueName, links }) {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(null);

    const handleOpenMenu = (event, label) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(label);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setOpenMenu(null);
    };

    const leagueLogo = loadLogo(leagueName);

    const getTeamLogoPath = (teamName) => {
        const formattedName = teamName.toLowerCase().replace(/\s+/g, '-');
        return require(`./${leagueName}/Logos/${formattedName}.png`);
    };

    return (
        <Container>
            <AppBar position="static" sx={{ backgroundColor: '#1f1f1f', marginTop: '30px' }}>
                <Toolbar>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <img src={leagueLogo} alt={`${leagueName} Logo`} style={{ height: 50, marginRight: 10 }} />
                        <Typography variant="h6" sx={{ color: 'white', marginRight: 3 }}>
                            {leagueName}
                        </Typography>
                    </Box>

                    <Tabs value={location.pathname} textColor="inherit" indicatorColor="secondary">
                        {links.map((link, index) => (
                            link.subLinks ? (
                                <Box key={index}>
                                    <Tab
                                        label={link.label}
                                        onClick={(event) => handleOpenMenu(event, link.label)}
                                        sx={{ color: 'white' }}
                                    />
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={openMenu === link.label}
                                        onClose={handleCloseMenu}
                                    >
                                        {link.subLinks.map((subLink, subIndex) => (
                                            <MenuItem
                                                key={subIndex}
                                                component={Link}
                                                to={subLink.path}
                                                onClick={handleCloseMenu}
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                {/* Team Logo */}
                                                {link.label === "Teams" && (
                                                    <img
                                                        src={getTeamLogoPath(subLink.label)}
                                                        alt={`${subLink.label} Logo`}
                                                        style={{
                                                            height: 30,
                                                            width: 30,
                                                            marginRight: 8,
                                                            borderRadius: '50%' // Optional, for circular logos
                                                        }}
                                                    />
                                                )}
                                                {subLink.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            ) : (
                                <Tab
                                    key={index}
                                    label={link.label}
                                    component={Link}
                                    to={link.path}
                                    value={link.path}
                                    sx={{ color: 'white' }}
                                />
                            )
                        ))}
                    </Tabs>
                </Toolbar>
            </AppBar>
        </Container>
    );
}

export default Navbar;
