// utils/aggregateStats.js
export const aggregateStats = (members, stat) => {
  const total = {
    sum: 0,
    count: members.length,
  };

  members.forEach((member) => {
    total.sum += parseFloat(member[stat]) || 0;
  });

  return total;
};

export const availableStats = [
  "Games Played",
  "C Gps",
  "LW Gps",
  "RW Gps",
  "D Gps",
  "Wins",
  "Losses",
  "OTLs",
  "Goals",
  "Assists",
  "Points",
  "PPG",
  "Shots",
  "Shots per game",
  "GWGs",
  "Passes",
  "Pass attempts",
  "Saucer passes",
  "Possession time",
  "+/-",
  "Offsides",
  "PIM",
  "Penalties Drawn",
  "Fights",
  "Fights Won",
  "Dekes",
  "Dekes Made",
  "Deflections",
  "Giveaways",
  "Hits",
  "Takeaways",
  "Interceptions",
  "Blocked shots",
  "PK zone clears",
  "Faceoffs",
  "Faceoff wins",
  "Faceoff losses",
  "Hattricks",
  "Xfactor zone stick checks",
  "Xfactor super-star stick checks",
  "Xfactor zone goals",
  "Xfactor zone assists",
  "Xfactor zone hits",
  "Xfactor zone times used",
  "Xfactor super-star goals",
  "Xfactor super-star assists",
  "Xfactor super-star hits",
  "Screen chances",
  "Screen goals",
  "Power play goals",
  "Goalie wins",
  "Goalie losses",
  "Goalie OTLs",
  "Goalie shots",
  "Goalie saves",
  "Goals allowed",
  "Shutouts",
  "Shutout periods",
  "Diving saves",
  "Breakaway shots against",
  "Breakaway saves",
  "Penalty shots against",
  "Penalty shot saves",
  "Shutout shots",
  "Shutout saves",
  "Goalie pokechecks",
  "Goalie penalty kill zone clears",
  "Goalie games played",
  "Xfactor superstar ability saves",
  "Xfactor zone ability saves",
];

