import React from 'react';
import { Box } from '@mui/material';
import StatBar from './StatBar';
import * as math from 'mathjs';

const ClubStatsDisplay = ({ clubData }) => {
    const wins = parseInt(clubData.team_stats.wins);
    const losses = parseInt(clubData.team_stats.losses) + parseInt(clubData.team_stats.otl); // Including OT losses
    const goals = parseInt(clubData.team_stats.goals);
    const goalsAgainst = parseInt(clubData.team_stats.goalsAgainst);
    const goalsPerGame = parseFloat(clubData.team_stats.goals_per_game);
    const goalsAgainstPerGame = parseFloat(clubData.team_stats.goalsAgainst_per_game);

    // Calculate weighted averages for goals scored and goals against
    const totalGames = wins + losses;
    const winWeight = wins / totalGames;
    const lossWeight = losses / totalGames;

    // Weighted average of goals scored per game, considering more weight to wins
    const weightedGoalsScored = math.multiply(goalsPerGame, winWeight);
    
    // Weighted average of goals conceded per game, considering more weight to losses
    const weightedGoalsAgainst = math.multiply(goalsAgainstPerGame, lossWeight);

    // Advanced Win Margin using weighted goals
    const winMargin = wins > 0 ? math.abs(weightedGoalsScored - weightedGoalsAgainst) : 0;

    // Calculate standard deviation of goals scored and goals conceded per game
    const stdDeviationGoalsScored = math.std(goalsPerGame, goalsAgainstPerGame);
    const stdDeviationGoalsAgainst = math.std(goalsAgainstPerGame, goalsPerGame);

    // Loss margin using weighted averages and standard deviation
    const lossMargin = losses > 0 
        ? math.abs(weightedGoalsAgainst + stdDeviationGoalsAgainst - weightedGoalsScored)
        : 0;

    return (
        <Box sx={{ margin: '10px' }}>
            <StatBar stat={clubData.clubRating["Club Overall Rating"]} statType={"Club rating"} max={99}/>
            <StatBar stat={winMargin.toFixed(2)} statType={"Average win margin"} max={5}/>
            <StatBar stat={lossMargin.toFixed(2)} statType={"Average loss margin"} max={5}/>
        </Box>
    );
};

export default ClubStatsDisplay;
