import React, { useState, useEffect, useRef } from 'react';
import { Chart, RadialLinearScale, ArcElement, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, Chip, Divider } from '@mui/material';
import { useStats } from '../../Contexts/StatsContext';
import StackedBarChart from './StackedBarChart'
// Register the components
Chart.register(RadialLinearScale, ArcElement, PointElement, LineElement, Filler, Tooltip, Legend);

function OverallRadarChart({ playerData }) {
  const skaterRatings = playerData.overall_results.overall_results
  const goalieRatings = playerData.overall_results.goalie_overall_results
  const skaterRecentRatings = playerData.playerRecentData !== "NoDataFound" ? playerData.playerRecentData?.averages?.["Overall ratings"]?.skaterRatings || null : null;
  const goalieRecentRatings = playerData.playerRecentData !== "NoDataFound" ? playerData.playerRecentData?.averages?.["Overall ratings"]?.goalieRatings || null : null;
  const position = playerData.Position
  const { state } = useStats();
  const dataFetched = useRef(false);
  const { positionState } = state;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [globalRatingsC, setGlobalRatingsC] = useState('');
  const [globalRatingsLW, setGlobalRatingsLW] = useState('');
  const [globalRatingsRW, setGlobalRatingsRW] = useState('');
  const [globalRatingsD, setGlobalRatingsD] = useState('');
  const [globalRatingsG, setGlobalRatingsG] = useState('');
  const [globalRatings, setGlobalRatings] = useState('');
  const [globalRanking, setGlobalRanking] = useState('');
  const [globalPosRanking, setGlobalPosRanking] = useState('');
  const [totalPosUsers, setTotalPosUsers] = useState('');
  const [userPos, setUserPos] = useState('');
  const [totalUsers, setTotalUsers] = useState('');
  const [selectedPosition, setSelectedPosition] = useState(position); // default position
  const [selectedPositionAcronym, setSelectedPositionAcronym] = useState(position); // default position
  const [chartInstance, setChartInstance] = useState(null);
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const handlePositionChange = (event) => {
    setSelectedPosition(event.target.value);
  };
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      // This will check if the chart instance exists and destroy it to avoid memory leaks
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [chartInstance]);

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        await sleep(1000)
        const response = await fetch('/api/average-ratings');
        let data = await response.json();
        data = data.averages_by_position

        for (let postionData in data) {
          if (data[postionData].position === 'center') {
            setGlobalRatingsC(data[postionData])
          } else if (data[postionData].position === 'leftWing') {
            setGlobalRatingsLW(data[postionData])
          } else if (data[postionData].position === 'rightWing') {
            setGlobalRatingsRW(data[postionData])
          } else if (data[postionData].position === 'defenseMen') {
            setGlobalRatingsD(data[postionData])
          } else if (data[postionData].position === 'goalie') {
            setGlobalRatingsG(data[postionData])
          }
        }

      } catch (error) {
        console.error('Error fetching average ratings:', error);
      }
    };

    const fetchRankings = async () => {
      try {
        let response = await fetch(`/api/rankings/${playerData.Username}`);
        let data = await response.json();

        // Check if the response contains a message indicating the user was not found
        if (data.message && data.message === "User not found") {
          await sleep(2000); // Wait for 5 seconds before retrying
          response = await fetch(`/api/rankings/${playerData.Username}`);
          data = await response.json();
        } else {
          data = data.data
        }

        if (data.overallRank && data.totalUsers) {
          setGlobalRanking(data.overallRank);
          setTotalUsers(data.totalUsers);
        } else {
          console.log('No ranking data available or user still not found after retry.');
        }
      } catch (error) {
        console.error('Error fetching rankings:', error);
      }
    };

    const fetchPosRankings = async () => {
      try {
        let response = await fetch(`/api/rankings/position/${playerData.Username}`);
        let data = await response.json();

        // Check if the response contains a message indicating the user was not found
        if (data.message && data.message === "No users found in the same position") {
          await sleep(1000); // Wait for 5 seconds before retrying
          response = await fetch(`/api/rankings/position/${playerData.Username}`);
          data = await response.json();

        } else {
          data = data.data

        }

        if (data.positionRank && data.totalUsersInPosition) {
          setGlobalPosRanking(data.positionRank);
          setTotalPosUsers(data.totalUsersInPosition);
        } else {
          console.log('No ranking data available or user still not found after retry.');
        }
      } catch (error) {
        console.error('Error fetching rankings:', error);
      }
    };

    const fetchAllRankings = async () => {
      try {
        let response = await fetch(`/api/all-rankings`);
        let data = await response.json();

        if (data.positionRank && data.totalUsersInPosition) {
          setGlobalPosRanking(data.positionRank);
          setTotalPosUsers(data.totalUsersInPosition);
        } else {
          console.log('No ranking data available or user still not found after retry.');
        }
      } catch (error) {
        console.error('Error fetching rankings:', error);
      }
    };

    if (!dataFetched.current) {
      fetchRatings();
      fetchRankings()
      fetchPosRankings()
      fetchAllRankings()
    }
    dataFetched.current = true;
  }, []);

  useEffect(() => {

    if (selectedPosition === 'center') {
      setGlobalRatings(globalRatingsC)
      setSelectedPositionAcronym('C')
    } else if (selectedPosition === 'leftWing') {
      setGlobalRatings(globalRatingsLW)
      setSelectedPositionAcronym('LW')
    } else if (selectedPosition === 'rightWing') {
      setGlobalRatings(globalRatingsRW)
      setSelectedPositionAcronym('RW')
    } else if (selectedPosition === 'defenseMen') {
      setGlobalRatings(globalRatingsD)
      setSelectedPositionAcronym('D')
    } else if (selectedPosition === 'goalie') {
      setGlobalRatings(globalRatingsG)
      setSelectedPositionAcronym('G')
    }

    if (playerData.Position === 'center') {
      setUserPos('C')
    } else if (playerData.Position === 'leftWing') {
      setUserPos('LW')
    } else if (playerData.Position === 'rightWing') {
      setUserPos('RW')
    } else if (playerData.Position === 'defenseMen') {
      setUserPos('D')
    } else if (playerData.Position === 'goalie') {
      setUserPos('G')
    }

  }, [globalRatingsC, globalRatingsLW, globalRatingsRW, globalRatingsD, globalRatingsG, selectedPosition]);

  let ratingsValues = []
  let recentRatingsValues = []
  let globalRatingsValues = []
 
  let usedLabels = []
  const hasRecentRatings = skaterRecentRatings || goalieRecentRatings;
  if (positionState === 'skater') {
    ratingsValues = [
      skaterRatings["Category Ratings"].SHT,
      skaterRatings["Category Ratings"].PSS,
      skaterRatings["Category Ratings"].HND,
      skaterRatings["Category Ratings"].PHS,
      skaterRatings["Category Ratings"].DEF,
    ].map(rating => rating || 0); // Ensure undefined ratings default to 0
    recentRatingsValues = hasRecentRatings ? [
      skaterRecentRatings["Category Ratings"].SHT,
      skaterRecentRatings["Category Ratings"].PSS,
      skaterRecentRatings["Category Ratings"].HND,
      skaterRecentRatings["Category Ratings"].PHS,
      skaterRecentRatings["Category Ratings"].DEF,
    ].map(rating => rating || 0) : [];

    if (!globalRatings) return null;
    globalRatingsValues = [
      globalRatings.avg_shooting?.toFixed(0) || 0,
      globalRatings.avg_passing?.toFixed(0) || 0,
      globalRatings.avg_handeye?.toFixed(0) || 0,
      globalRatings.avg_physicality?.toFixed(0) || 0,
      globalRatings.avg_defense?.toFixed(0) || 0,
    ].map(rating => rating || 0);

    usedLabels = ['SHT', 'PSS', 'HND', 'PHS', 'DEF']
  } else if (positionState === 'goalie') {
    ratingsValues = [
      goalieRatings["Category Ratings"].REF,
      goalieRatings["Category Ratings"].CON,
      goalieRatings["Category Ratings"].CLU,
    ].map(rating => rating || 0); // Ensure undefined ratings default to 0

    recentRatingsValues = hasRecentRatings ? [
      goalieRecentRatings["Category Ratings"].REF,
      goalieRecentRatings["Category Ratings"].CON,
      goalieRecentRatings["Category Ratings"].CLU,
    ].map(rating => rating || 0) : [];

    if (!globalRatings) return null;
    globalRatingsValues = [
      globalRatings.avg_reflexes?.toFixed(0) || 0,
      globalRatings.avg_consistency?.toFixed(0) || 0,
      globalRatings.avg_clutchness?.toFixed(0) || 0,
    ].map(rating => rating || 0);
    usedLabels = ['REF', 'CON', 'CLU']
  }
  // Data for the radar chart

  const datasets = [
    {
      label: 'Overall rating (Career)',
      data: ratingsValues,
      fill: true,
      backgroundColor: 'rgba(25, 118, 210, 0.6)', // Adjusted for some transparency
      borderColor: '#1a7bb8',
      pointBackgroundColor: 'white',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#1a7bb8',
      pointHoverBorderColor: '#fff',
      pointRadius: 6,
      pointHoverRadius: 5,
    },
    {
      label: `Global Average Overall Ratings (${selectedPositionAcronym})`,
      data: globalRatingsValues,
      fill: true,
      backgroundColor: 'rgba(255, 42, 42, 0.2)', // Adjusted for some transparency
      borderColor: '#ff2a2ade',
      pointBackgroundColor: 'white',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#ff2a2ade',
      pointHoverBorderColor: '#fff',
      pointRadius: 4,
      pointHoverRadius: 5,
    },
  ]

  if (hasRecentRatings) {
    datasets.push({
      label: 'Overall rating (Last 5 games)',
      data: recentRatingsValues,
      fill: true,
      backgroundColor: 'rgba(33, 177, 76, 0.2)',
      borderColor: '#21b14cde',
      pointBackgroundColor: 'white',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#21b14cde',
      pointHoverBorderColor: '#fff',
      pointRadius: 4,
      pointHoverRadius: 5,
    });
  }

  const data = {
    labels: usedLabels,
    datasets: datasets
  };

  const minRating = Math.min(...ratingsValues, recentRatingsValues)

  const maxRating = 99

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            let label = context[0].label;
            if (context[0].parsed !== null) {
              label += ': ' + context[0].parsed.r;
            }
            return label;
          },
          label: function () {
            return '';
          },
        },
        titleFont: { size: 16 }, // Set tooltip title font size
      },
      legend: {
        display: true,
      },
    },
    elements: {
      line: {
        borderWidth: 5,
        borderColor: 'white',
      },
    },
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        ticks: {
          display: true,
          stepSize: 10,
          font: {
            size: 14,
            family: 'Helvetica',
            style: 'normal',
            weight: '600',
          },
          color: '#fff',
          backdropColor: 'transparent'
        },
        grid: {
          lineWidth: 0.2,
          color: '#ddd',
        },
        pointLabels: {
          padding: 2,
          font: {
            size: 14,
            family: 'Helvetica',
            style: 'normal',
            weight: '600',
          },
          color: '#fff',
        },
        min: minRating,
        max: maxRating,
      },
    },
  };

  return (
    <Box sx={{ marginBottom: windowWidth < 600 ? 8 : windowWidth < 900 ? 3 : 3 }}>

      <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: windowWidth < 600 ? 5 : windowWidth < 900 ? 2 : 2, justifyContent: 'space-between' }}>
        <Typography variant="h5" sx={{ color: 'white', display: 'flex', width: 'fit-content', marginTop: 2 }}>Overall ratings</Typography>
        <FormControl sx={{ marginTop: 2, width: windowWidth < 'unset' ? windowWidth : windowWidth < 900 ? '150px' : '150px' }}>
          <InputLabel id="position-select-label" sx={{ color: 'gray', '&.Mui-focused': { color: 'white' } }}>Position</InputLabel>
          <Select
            labelId="position-select-label"
            id="position-select"
            value={selectedPosition}
            label="Position"
            onChange={handlePositionChange}
            sx={{
              color: 'white', // Text color
              '& .MuiSelect-icon': { color: 'white' }, // Dropdown icon color
              '& .MuiOutlinedInput-notchedOutline': { borderColor: 'gray' }, // Border color
            }}
          >
            <MenuItem value="center">Center</MenuItem>
            <MenuItem value="leftWing">Left Wing</MenuItem>
            <MenuItem value="rightWing">Right Wing</MenuItem>
            <MenuItem value="defenseMen">Defensemen</MenuItem>
            <MenuItem value="goalie">Goalie</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 8, width: '100%', gap: 1, flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Chip variant="outlined" label={positionState == "skater" ? skaterRatings['Overall Rating']  + ' OVR' : goalieRatings['Overall Rating'] + ' OVR'} sx={{ backgroundColor: '#1f1f1f', color: 'white' }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Chip variant="outlined" label={(globalRatings.avg_overall).toFixed(0) + ' Global Avg for ' + selectedPositionAcronym} sx={{ backgroundColor: '#1f1f1f', color: 'white', }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Chip variant="outlined" label={'Rank #' + globalPosRanking + '/' + totalPosUsers + ' for ' + userPos} sx={{ backgroundColor: '#1f1f1f', color: 'white' }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Chip variant="outlined" label={'Global Rank #' + globalRanking + '/' + totalUsers} sx={{ backgroundColor: '#1f1f1f', color: 'white' }} />
        </Box>
      </Box>
      <div>
        <Radar data={data} options={options} style={{ width: windowWidth < 600 ? windowWidth : windowWidth < 900 ? '400px' : '400px', height: windowWidth < 600 ? windowWidth : windowWidth < 900 ? '400px' : '400px' }} />
      </div>

      <StackedBarChart playerData={playerData}/>

    </Box>
  );
}

export default OverallRadarChart;
