import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

function ThreeStarsDisplay({ stars }) {
    if (!stars) return null;

    const getStarIcons = (count) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {[...Array(count)].map((_, i) => (
                <StarIcon key={i} style={{ color: 'white' }} />
            ))}
        </div>
    );

    return (
        <Grid container spacing={1} justifyContent="center">
            {stars.map((star, index) => (
                <Grid item key={index} xs={12} sm={4}>
                    <Card
                        variant="outlined"
                        sx={{
                            margin: 1,
                            backgroundColor: '#171717',
                            boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)',
                            border: 'solid #656565 .001rem',
                        }}
                    >
                        <CardContent>
                            {getStarIcons(index + 1)}
                            <Typography variant="h6" sx={{ color: 'white' }}>
                                {star.playerName}
                            </Typography>
                            <Typography sx={{ color: 'white' }}>
                                Team: {star.teamName}
                            </Typography>
                            <Typography sx={{ color: 'white' }}>
                                Score: {star.performanceScore.toFixed(1)}
                            </Typography>
                            {/* Conditional rendering for skater stats */}
                            {'goals' in star && (
                                <>
                                    <Typography sx={{ color: 'white' }}>
                                        Goals: {star.goals}
                                    </Typography>
                                    <Typography sx={{ color: 'white' }}>
                                        Assists: {star.assists}
                                    </Typography>
                                    <Typography sx={{ color: 'white' }}>
                                        Hits: {star.hits || 0}
                                    </Typography>
                                    <Typography sx={{ color: 'white' }}>
                                        Takeaways: {star.takeaways || 0}
                                    </Typography>
                                    <Typography sx={{ color: 'white' }}>
                                        Interceptions: {star.interceptions || 0}
                                    </Typography>
                                </>
                            )}
                            {/* Conditional rendering for goalie stats */}
                            {'saves' in star && (
                                <>
                                    <Typography sx={{ color: 'white' }}>
                                        Saves: {star.saves}
                                    </Typography>
                                    <Typography sx={{ color: 'white' }}>
                                        Save %: {star.savePercentage || 0}%
                                    </Typography>
                                    <Typography sx={{ color: 'white' }}>
                                        GAA: {star.gaa || 0}
                                    </Typography>
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default ThreeStarsDisplay;
