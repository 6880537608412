import React, { useState } from 'react';
import PlayerStatChart from './PlayerStatsChart';
import { aggregateStats, availableStats } from './aggregateStats';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const PlayerComparison = ({ memberData }) => {
  const [selectedStat, setSelectedStat] = useState(availableStats[0]); // Default to the first stat

  const handleChange = (event) => {
    setSelectedStat(event.target.value);
  };

  // Aggregate the selected stat
  const aggregatedData = aggregateStats(memberData, selectedStat);

  return (
    <Box>
      {/* Dropdown for selecting stats */}
      <FormControl fullWidth variant="outlined" sx={{
            color: 'blue',
            '& .MuiSelect-icon': { color: 'white' },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'lightblue',
            }
          }}>
        <InputLabel sx={{color: 'white'}}>Select Stat</InputLabel>
        <Select value={selectedStat} onChange={handleChange} label="Select Stat" sx={{color: 'white'}}>
          {availableStats.map((stat, index) => (
            <MenuItem key={index} value={stat}>
              {stat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Render the stat comparison chart */}
      <PlayerStatChart memberData={memberData} selectedStat={selectedStat} aggregatedData={aggregatedData} />
    </Box>
  );
};

export default PlayerComparison;
