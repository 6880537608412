import React from 'react';
import { Avatar, Box, Paper, Stack, Typography, Divider, Grid } from '@mui/material';

function PlayerRow({ player, stat, category }) {
    return (
        <Grid container alignItems="center" sx={{ width: '100%', paddingY: 0.5 }}>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px', flexGrow: 1, color: 'white' }}>
                    {player.Username}
                </Typography>
            </Grid>
            <Grid item xs={3} sx={{ width: 'fit-content', textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white' }}>{category}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ width: 'fit-content', textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white' }}>{stat}</Typography>
            </Grid>
        </Grid>
    );
}

function ClubLeaders({ clubData }) {
    const { topScorers, topPlaymakers, topDefenders } = clubData.topPlayers;

    const StatHeader = ({ stat }) => (
        <Grid container spacing={1} paddingY={1}>
            <Grid item xs={6}>
                <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>Player</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>OVR</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>{stat}</Typography>
            </Grid>
        </Grid>
    );

    return (
        <Stack spacing={2} sx={{
            width: '100%',
            marginX: 'auto',
            marginTop: 2,
        }}>
            {/* Top Scorers */}
            <Paper sx={{ backgroundColor: '#171717', padding: 2, paddingY: 2, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', borderRadius: 5 }}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px', color: 'white' }}>Top shooters</Typography>
                <Divider light />
                <StatHeader stat={'Goals'} />
                {topScorers
                    .filter(player => player.overallRating && player.overallRating["Category Ratings"] && player.overallRating["Category Ratings"].SHT !== undefined)
                    .sort((a, b) => b.overallRating["Category Ratings"].SHT - a.overallRating["Category Ratings"].SHT) // Sort by shooting rating descending
                    .map(player => (
                        <PlayerRow key={player.Username} player={player} stat={player.Goals} category={player.overallRating["Category Ratings"].SHT} />
                    ))}
            </Paper>

            {/* Top Passers */}
            <Paper sx={{ backgroundColor: '#171717', padding: 2, paddingY: 2, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', borderRadius: 5 }}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px', color: 'white' }}>Top passers</Typography>
                <Divider light />
                <StatHeader stat={'Assists'} />
                {
                    topPlaymakers
                        .filter(player => player.overallRating && player.overallRating["Category Ratings"] && player.overallRating["Category Ratings"].PSS !== undefined)
                        .sort((a, b) => b.overallRating["Category Ratings"].PSS - a.overallRating["Category Ratings"].PSS) // Sort by passing rating descending
                        .map(player => (
                            <PlayerRow key={player.Username} player={player} stat={player.Assists} category={player.overallRating["Category Ratings"].PSS} />
                        ))
                }
            </Paper>

            {/* Top Handeye */}
            <Paper sx={{ backgroundColor: '#171717', padding: 2, paddingY: 2, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', borderRadius: 5 }}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px', color: 'white' }}>Top handeye</Typography>
                <Divider light />
                <StatHeader stat={'Dekes'} />
                {
                    topPlaymakers
                        .filter(player => player.overallRating && player.overallRating["Category Ratings"] && player.overallRating["Category Ratings"].HND !== undefined)
                        .sort((a, b) => b.overallRating["Category Ratings"].HND - a.overallRating["Category Ratings"].HND) // Sort by handeye rating descending
                        .map(player => (
                            <PlayerRow key={player.Username} player={player} stat={player.Dekes} category={player.overallRating["Category Ratings"].HND} />
                        ))
                }
            </Paper>

            {/* Top Defenders */}
            <Paper sx={{ backgroundColor: '#171717', padding: 2, paddingY: 2, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', borderRadius: 5 }}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px', color: 'white' }}>Top defenders</Typography>
                <Divider light />
                <StatHeader stat={'Ints'} />
                {
                    topDefenders
                        .filter(player => player.overallRating && player.overallRating["Category Ratings"] && player.overallRating["Category Ratings"].DEF !== undefined)
                        .sort((a, b) => b.overallRating["Category Ratings"].DEF - a.overallRating["Category Ratings"].DEF) // Sort by defense rating descending
                        .map(player => (
                            <PlayerRow key={player.Username} player={player} stat={player.Interceptions} category={player.overallRating["Category Ratings"].DEF} />
                        ))
                }
            </Paper>

            {/* Top Hitters */}
            <Paper sx={{ backgroundColor: '#171717', padding: 2, paddingY: 2, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', borderRadius: 5 }}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px', color: 'white' }}>Top hitters</Typography>
                <Divider light />
                <StatHeader stat={'Hits'} />
                {
                    topDefenders
                        .filter(player => player.overallRating && player.overallRating["Category Ratings"] && player.overallRating["Category Ratings"].PHS !== undefined)
                        .sort((a, b) => b.overallRating["Category Ratings"].PHS - a.overallRating["Category Ratings"].PHS) // Sort by physical rating descending
                        .map(player => (
                            <PlayerRow key={player.Username} player={player} stat={player.Hits} category={player.overallRating["Category Ratings"].PHS} />
                        ))
                }
            </Paper>
        </Stack>
    );
}

export default ClubLeaders;
