// GameCarousel.js
import React, { useState, useEffect, useRef } from 'react';
import GameCard from './GameCard';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const TeamsRecentGames = ({ gamesData, league, team, type }) => {
    if (team == 'Tokyo Electric Hounds') {
        team = 'Tky Electric Hounds'
    } else if (team == 'Vancouver Grizzlies') {
        team = 'VANCOUVER GRIZZLIES'
    } else if (team == 'Belle Haven Bucks') {
        team = 'BELLE HAVEN BUCKS'
    }
    const [sortedGames, setSortedGames] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        // Sort by date in descending order and reverse to have the most recent first
        const sorted = Object.values(gamesData).sort((a, b) => new Date(a.game.Date) - new Date(b.game.Date));
        setSortedGames(sorted);

    }, [gamesData]);

    const navigateToGameStats = (matchID) => {
        navigate(`/${league}/${hyphenateTeamName(team)}/games/${matchID}`); // Navigate to game stats page
    };

    const groupGamesByMonth = (games) => {
        return games.reduce((acc, game) => {
            const date = new Date(game.game.Date);
            const monthYear = date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }); // e.g., "November 2024"

            if (!acc[monthYear]) {
                acc[monthYear] = [];
            }

            acc[monthYear].push(game);
            return acc;
        }, {});
    };

    const DateCircle = ({ dateString }) => {
        // Format the date string
        const date = new Date(dateString);
        const dayNumber = date.getDate(); // Get the day number
        const dayName = date.toLocaleDateString('en-US', { weekday: 'short' }); // Get the short weekday name (e.g., "Fri")

        return (
            <Box
                sx={{
                    width: 54, // Circle diameter
                    height: 53,
                    borderRadius: '50%', // Makes it circular
                    backgroundColor: '#202020', // Adjust color as needed
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for a 3D effect
                    textAlign: 'center',
                    fontSize: 14, // General font size
                }}
            >
                <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 'bold', margin: 0, color: '#B0B0B0' }}>
                    {dayNumber}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: 10, margin: 0, color: '#B0B0B0', fontWeight: 500 }}>
                    {dayName.toLocaleUpperCase()}
                </Typography>
            </Box>
        );
    };

    function formatTeamName(teamName) {
        // Split the team name to remove the league prefix and join remaining parts
        const formattedName = teamName.split(' ').slice(1).join(' ');

        // Convert to lowercase and replace spaces with hyphens
        return formattedName.toLowerCase().replace(/\s+/g, '-');
    }

    function hyphenateTeamName(teamName) {
        // Convert to lowercase and replace spaces with hyphens
        return teamName.toLowerCase().replace(/\s+/g, '-');
    }

    function getLastPartOfTeamName(teamName) {
        if (!teamName || typeof teamName !== 'string') {
            return ''; // Return an empty string for invalid input
        }

        const words = teamName.split(' '); // Split the name into words
        return words[words.length - 1]; // Return the last word
    }

    const loadLogo = (teamName, league) => {
        try {
            return require(`./${league}/Logos/${formatTeamName(teamName)}.png`);
        } catch (error) {
            console.error("Error loading league logo:", error);
            return null;
        }
    };

    function removeLeaguePrefix(teamName) {
        // Remove the first word (assumed to be the league prefix)
        return teamName.split(' ').slice(1).join(' ');
    }

    function getTeamResult(teamName, gameData) {
        if (!teamName || !gameData || typeof teamName !== 'string') {
            return ''; // Return an empty string for invalid input
        }

        // Extract the relevant information from the gameData
        const team1Name = gameData.game['Team 1'];
        const team2Name = gameData.game['Team 2'];
        const team1Score = parseInt(gameData.game['Team 1 Score'], 10);
        const team2Score = parseInt(gameData.game['Team 2 Score'], 10);

        if (teamName === removeLeaguePrefix(team1Name)) {
            return team1Score > team2Score ? `W ${team1Score} - ${team2Score}` : `L ${team2Score} - ${team1Score}`;
        } else if (teamName === removeLeaguePrefix(team2Name)) {
            return team2Score > team1Score ? `W ${team2Score} - ${team1Score}` : `L ${team1Score} - ${team2Score}`;
        }

        // If the team name is not found in the game data
        return '';
    }

    const getOpponentLogo = (team, gameData, league) => {
        const team1 = removeLeaguePrefix(gameData.game['Team 1']);
        const team2 = removeLeaguePrefix(gameData.game['Team 2']);

        // Determine the opponent team
        const opponentTeam = team.toLowerCase() === team1.toLowerCase() ? team2 : team1;
        const opp = `${league} ${opponentTeam}`
        // Load the opponent's logo
        return loadLogo(opp, league);
    };

    const getOpponentName = (team, gameData, league) => {
        const team1 = removeLeaguePrefix(gameData.game['Team 1']);
        const team2 = removeLeaguePrefix(gameData.game['Team 2']);

        // Determine the opponent team
        const opponentTeam = team.toLowerCase() === team1.toLowerCase() ? team2 : team1;

        // Load the opponent's logo
        return opponentTeam;
    };

    const GamesByMonth = ({ sortedGames, team, league }) => {
        const gamesByMonth = groupGamesByMonth(sortedGames);

        return (
            <Box sx={{ width: '100%', marginTop: 2 }}>
                {Object.entries(gamesByMonth).map(([month, games]) => (
                    <React.Fragment key={month}>
                        {/* Month Header */}
                        <Typography
                            variant="h6"
                            sx={{
                                color: 'white',
                                fontWeight: 'bold',
                                marginBottom: 2,
                                marginTop: 4,
                            }}
                        >
                            {month}
                        </Typography>

                        {/* Games */}
                        {games.filter(gameData => gameData.game['Team 1'] !== undefined && gameData.game['Team 2'] !== undefined).map((gameData, index) => (

                            <Box
                                key={index}
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 2fr 1fr', // Date, Opponent Info, Result
                                    alignItems: 'center',
                                    padding: '8px 0', // Spacing between rows
                                    width: '100%',
                                    position: 'relative',
                                    cursor: 'pointer', // Indicate clickable area
                                    '&:hover': { backgroundColor: '#333' },
                                }}
                                onClick={() => navigateToGameStats(gameData.game['Match ID'])}
                            >
                                {/* Date (Far Left) */}
                                <Box sx={{ justifySelf: 'start', marginLeft: type === 'profile' ? 1 : 3 }}>
                                    <DateCircle dateString={gameData.game.Date} />
                                </Box>

                                {/* Opponent Info (Middle Left) */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1, // Space between elements
                                    }}
                                >
                                    <Typography sx={{ color: '#B0B0B0' }}>vs</Typography>
                                    <img
                                        src={getOpponentLogo(team, gameData, league)}
                                        alt="Team Crest"
                                        style={{ width: 20, height: 20 }}
                                    />
                                    <Typography sx={{ color: '#B0B0B0' }}>{getLastPartOfTeamName(getOpponentName(team, gameData, league))}</Typography>
                                </Box>

                                {/* Result (Far Right) */}
                                <Box sx={{ justifySelf: 'end', marginRight: type === 'profile' ? 1 : 3 }}>
                                    <Typography sx={{ color: '#B0B0B0' }}>{getTeamResult(team, gameData)}</Typography>
                                </Box>

                                <Box
                                    sx={{
                                        gridColumn: '2 / -1', // Span all columns
                                        height: '1px',
                                        backgroundColor: '#191919',
                                        marginTop: '8px', // Space above the line
                                    }}
                                />
                            </Box>
                        ))}
                    </React.Fragment>
                ))}
            </Box>
        );
    };

    if (!gamesData) return
    return (
        <GamesByMonth sortedGames={sortedGames} team={team} league={league} />

    )
}

export default TeamsRecentGames;
