import React from 'react';
import { Box, Typography, styled } from '@mui/material';

const PositionSegment = styled('div')(({ theme, width, bgcolor }) => ({
    height: '20px',
    flexGrow: 0,  // No flex growth
    flexShrink: 0,  // No flex shrink
    flexBasis: `${width}%`,  // Base width as calculated
    backgroundColor: bgcolor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',  // Ensures text color is visible on any background
}));

const PositionalProgressBar = ({ totalGames, gamesByPosition }) => {
    const positions = Object.keys(gamesByPosition);

    return (
        <Box sx={{ marginTop: 2, overflow: 'hidden', borderRadius: '20px', backgroundColor: '#e0e0e0', height: '20px' }}>
            <Box display="flex" width="100%" height="100%">
                {positions.map((position) => {
                    const gamesPlayed = gamesByPosition[position];
                    if (gamesPlayed === 0) return null; // Exclude positions with 0 games played
                    const width = (gamesPlayed / totalGames) * 100;
                    const color = positionColors(position);
                    return (
                        <PositionSegment key={position} width={width} bgcolor={color} sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                            {gamesPlayed}
                        </PositionSegment>
                    );
                })}
            </Box>
        </Box>
    );
};

// Helper function to determine color based on position 
function positionColors(position) {
    switch (position) {
        case 'D': return '#ffc107';
        case 'C': return '#d72121';
        case 'LW': return '#4caf50';
        case 'RW': return '#2196f3';
        case 'G': return '#9321d7';
        default: return '#000'; // Default color if needed
    }
}

export default PositionalProgressBar;
