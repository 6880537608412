import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoading } from '../Contexts/LoadingContext';
import { Box, Container, Typography, Divider, Button } from '@mui/material';
import PlayerInfo from './PlayerInfo';
import OverallRadarChart from './OverallRadarChart';
import HockeyDataComponent from './HockeyDataComponent';
import { PropagateLoader } from 'react-spinners';
import PositionToggle from '../EASHL/Players/PositionToggle';
import { useStats } from '../Contexts/StatsContext';
import Snackbar from '@mui/material/Snackbar';

function PlayerProfile({ league }) {
    const { username } = useParams();
    const [skaterStats, setSkaterStats] = useState({});
    const [goalieStats, setGoalieStats] = useState({});
    const [playersTeam, setPlayersTeam] = useState({});
    const { loading, setLoading } = useLoading();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { state, dispatch } = useStats();
    const { positionState } = state;
    const [gameData, setGameData] = useState();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const CATEGORY_MAPPINGS_SK = {
        "SHT": [
            'Goals per Game',
            'ShotPerc',
            'Shots per Game',
            'Shots on net Perc',
        ],
        "PSS": [
            'Assists per Game',
            'PassPerc',
            'Passes per Game',
            'Saucer passes per Game',
            'Giveaways per Game'
        ],
        "HND": [
            'FaceoffPerc',
            'Deflections per Game',
            'Possession Per Game(Minutes)',
        ],
        "PHS": [
            'Penalties drawn per Game',
            'PIM per Game',
            'Hits per Game'
        ],
        "DEF": [
            'Takeaways per Game',
            'Interceptions per Game',
            'Blocked shots per Game',
            '(+/-) per Game'
        ]
    };

    const CATEGORY_MAPPINGS_G = {
        "REF": ["Diving Saves Per Game", "Pokechecks Per Game", "Saves Per Game"],
        "CON": ["Save %", "GAA"],
        "CLU": ["Shutouts Per Game", "Shutout Periods Per Game", "Breakaway Save %", "Penalty Shot Save %"]
    };

    const WEIGHTS_SK = {
        'TO': 1.5,
        'TD': 1.5,
        'Goals per Game': .95,
        'Assists per Game': .95,
        'ShotPerc': .005,
        'Shots per Game': 0.001,
        'PassPerc': .06,
        'Passes per Game': 0.02,
        'Saucer passes per Game': 1,
        'Deflections per Game': .75,
        'Penalties drawn per Game': 1.25,
        'PIM per Game': -0.35,
        'Hits per Game': 0.28,
        'Giveaways per Game': -0.11,
        'Takeaways per Game': 0.2,
        'Interceptions per Game': 0.2,
        'Blocked shots per Game': 0.35,
        'FaceoffPerc': 0.05,
        'Possession Per Game(Minutes)': 0.05,
        'Shots on net Perc': 0.0025,
        '(+/-) per Game': 0.06,
    };

    const WEIGHTS_G = {
        "GAA": -0.15,
        "Saves Per Game": 0.08,
        "Diving Saves per game": 0.03,
        "Save %": 0.2,
        "Breakaway Save %": 1,
        "Penalty Shot save %": 1,
        "Pokechecks Per Game": 0.03,
        "Shutouts Per Game": 5,
        "Shutout Periods Per Game": 1.7
    };

    const processPlayerData = (data, headers, isGoalie) => {
        const playerData = data.find(row => row[0] === username);
        if (!playerData) return {};

        const playerStats = {};
        headers.forEach((header, index) => {
            playerStats[header] = playerData[index];
        });

        // Perform calculations
        if (playerStats['Games Played'] && playerStats['Points']) {
            playerStats['Points per Game'] = (playerStats['Points'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Assists']) {
            playerStats['Assists per Game'] = (playerStats['Assists'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Goals']) {
            playerStats['Goals per Game'] = (playerStats['Goals'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Passes']) {
            playerStats['Passes per Game'] = (playerStats['Passes'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Saucer passes']) {
            playerStats['Saucer passes per Game'] = (playerStats['Saucer passes'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Deflections']) {
            playerStats['Deflections per Game'] = (playerStats['Deflections'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Penalties drawn']) {
            playerStats['Penalties drawn per Game'] = (playerStats['Penalties drawn'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['PIM']) {
            playerStats['PIM per Game'] = (playerStats['PIM'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Hits']) {
            playerStats['Hits per Game'] = (playerStats['Hits'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Giveaways']) {
            playerStats['Giveaways per Game'] = (playerStats['Giveaways'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Interceptions']) {
            playerStats['Interceptions per Game'] = (playerStats['Interceptions'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Takeaways']) {
            playerStats['Takeaways per Game'] = (playerStats['Takeaways'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Blocked shots']) {
            playerStats['Blocked shots per Game'] = (playerStats['Blocked shots'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['(+/-)']) {
            playerStats['(+/-) per Game'] = (playerStats['(+/-)'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Shots']) {
            playerStats['Shots per Game'] = (playerStats['Shots'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Shot attempts']) {
            playerStats['Shot attempts per game'] = (playerStats['Shot attempts'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Missed shots']) {
            playerStats['Missed shots per game'] = (playerStats['Missed shots'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Powerplay goals']) {
            playerStats['Powerplay goals per game'] = (playerStats['Powerplay goals'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Shorthanded goals']) {
            playerStats['Shorthanded goals per game'] = (playerStats['Shorthanded goals'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Pass attempts']) {
            playerStats['Pass attempts per game'] = (playerStats['Pass attempts'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Missed Shots']) {
            playerStats['Missed shots per game'] = (playerStats['Missed Shots'] / playerStats['Games Played']).toFixed(2);
        }

        if (playerStats['Team Name']) {
            playerStats['transformedTeamName'] = transformTeamName(playerStats['Team Name']);
        }

        if (playerStats['Shot %']) {
            playerStats['ShotPerc'] = playerStats['Shot %'].replace('%', '');
        }

        if (playerStats['Pass %']) {
            playerStats['PassPerc'] = playerStats['Pass %'].replace('%', '');
        }

        if (playerStats['Faceoff %']) {
            playerStats['FaceoffPerc'] = playerStats['Faceoff %'].replace('%', '');
        }

        if (playerStats['Shots on net %']) {
            playerStats['Shots on net Perc'] = playerStats['Shots on net %'].replace('%', '');
        }

        if (playerStats['Diving Saves']) {
            playerStats['Diving Saves Per Game'] = (playerStats['Diving Saves'] / playerStats['Games Played']).toFixed(2)
        }

        if (playerStats['Pokechecks']) {
            playerStats['Pokechecks Per Game'] = (playerStats['Pokechecks'] / playerStats['Games Played']).toFixed(2)
        }

        if (playerStats['Saves']) {
            playerStats['Saves Per Game'] = (playerStats['Saves'] / playerStats['Games Played']).toFixed(2)
        }

        if (isGoalie) {
            playerStats['Overall rating'] = calculateOverallRatingG(playerStats, WEIGHTS_G, playerStats['Games Played'], CATEGORY_MAPPINGS_G, playerStats['Penalty shot attempts per game']);
        } else {
            playerStats['Overall rating'] = calculateOverallRatingSK(playerStats);
        }

        let playstyle = "";
        let overall_cats = playerStats['Overall rating']['Category Ratings']
        let position = playerStats.Position;
        if (position == "rightWing") {
            position = "RW";
        } else if (position == "leftWing") {
            position = "LW";
        } else if (position == "center") {
            position = "C";
        } else if (position == "defenseMen") {
            position = "D";
        } else if (position == "goalie") {
            position = "G";
        }

        let sortedCats = Object.keys(overall_cats).sort((a, b) => overall_cats[b] - overall_cats[a]);

        // Initialize an array to hold the largest categories
        let largestCats = [sortedCats[0], sortedCats[1]];

        if (['C', 'LW', 'RW', 'G'].includes(position)) {

            switch (largestCats[0]) {
                case "SHT":
                    playstyle = "Sniper";
                    break;
                case "PSS":
                    playstyle = "Playmaker";
                    break;
                case "HND":
                    playstyle = "Dangler";
                    break;
                case "PHS":
                    switch (largestCats[1]) {
                        case "SHT":
                            playstyle = "Hitting Sniper";
                            break;
                        case "PSS":
                            playstyle = "Hitting Playmaker";
                            break;
                        case "HND":
                            playstyle = "Hitting Dangler";
                            break;
                        case "DEF":
                            playstyle = "Two-way Forward";
                            break;
                    }
                    break;
                case "DEF":
                    switch (largestCats[1]) {
                        case "SHT":
                            playstyle = "Defensive Sniper";
                            break;
                        case "PSS":
                            playstyle = "Defensive Playmaker";
                            break;
                        case "HND":
                            playstyle = "Defensive Dangler";
                            break;
                        case "PHS":
                            playstyle = "Two-way Forward";
                            break;
                    }
                    break;
            }
        } else if (['D', 'G'].includes(position)) {
            switch (largestCats[0]) {
                case "SHT":
                    switch (largestCats[1]) {
                        case "PSS":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "HND":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "PHS":
                            playstyle = "Two-way Defenseman";
                            break;
                        case "DEF":
                            playstyle = "Two-way Defenseman";
                            break;
                    }
                    break;
                case "HND":
                    switch (largestCats[1]) {
                        case "PSS":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "SHT":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "PHS":
                            playstyle = "Two-way Defenseman";
                            break;
                        case "DEF":
                            playstyle = "Two-way Defenseman";
                            break;
                    }
                    break;
                case "PSS":
                    switch (largestCats[1]) {
                        case "SHT":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "HND":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "PHS":
                            playstyle = "Puck-moving Defenseman";
                            break;
                        case "DEF":
                            playstyle = "Puck-moving Defenseman";
                            break;
                    }
                    break;
                case "PHS":
                    playstyle = "Defensive Defenseman";
                    break;
                case "DEF":
                    playstyle = "Defensive Defenseman";
                    break;
            }
        }
        playerStats['Playstyle'] = playstyle

        return playerStats;
    };

    const filterColumns = (headers, rows, startColIndex, endColIndex) => {
        const validHeaders = headers.slice(startColIndex, endColIndex + 1);
        const validRows = rows.map(row => row.slice(startColIndex, endColIndex + 1));
        return { validHeaders, validRows };
    };

    const transformTeamName = (teamName) => {
        return teamName
            .replace(/^[^\s]+\s/, '') // Remove the league prefix (e.g., "NAEHL ")
            .toLowerCase()
            .replace(/\s+/g, '-'); // Replace spaces with hyphens
    };

    function structureData(data) {
        // Assuming the first row is headers and remaining rows are data
        const headers = data.values[0];
        const rows = data.values.slice(1);

        return rows.map(row => {
            const userObj = {};
            headers.forEach((header, index) => {
                userObj[header] = row[index];
            });
            return userObj;
        });
    }

    function findUserTeam(username, data) {
        const user = data.find(user => user.Username === username);
        if (user) {
            return {
                teamId: user["Latest Team ID"],
                teamName: user["Latest Team"]
            };
        } else {
            return null; // User not found
        }
    }

    const calculateCategoryRatingSK = (stats, category, scalingNum = 60) => {
        let categoryRating = 0;
        const maxOvr = 99;
        const minOvr = 50;

        CATEGORY_MAPPINGS_SK[category].forEach(statName => {
            let weight = WEIGHTS_SK[statName] || 0;
            let statValue = parseFloat(stats[statName] || 0);
            if (statName.includes("%")) {
                statValue /= 100.0;
            }

            categoryRating += statValue * weight;
        });

        categoryRating *= 100;
        categoryRating = Math.max(categoryRating, minOvr);
        let scaledOvr = maxOvr * (1 - Math.exp(-categoryRating / scalingNum));
        scaledOvr = Math.max(Math.round(scaledOvr), minOvr);
        return scaledOvr;
    };

    const calculateOverallRatingSK = (stats) => {
        let compositeOvr = 0.0;
        let allCategoryRatings = {};

        for (let category in CATEGORY_MAPPINGS_SK) {
            let rating = calculateCategoryRatingSK(stats, category);
            allCategoryRatings[category] = rating;
        }

        let weightedAverageForPosition = Object.values(allCategoryRatings).reduce((a, b) => a + b, 0) / Object.keys(allCategoryRatings).length;
        compositeOvr = Math.round(weightedAverageForPosition);

        return {
            "Overall Rating": compositeOvr,
            "Category Ratings": allCategoryRatings
        };
    };

    const calculateCategoryRatingG = (stats, weights, category, isThreeVsThreePlayer) => {
        let categoryRating = 0;
        const PENALTY_FACTOR = 1.07; // Boost factor for 3s players
        const maxOvr = 99;
        const minOvr = 50;

        CATEGORY_MAPPINGS_G[category].forEach(statName => {
            let weight = weights[statName] || 0;
            let statValue = 0;

            if (stats[statName] && !stats[statName].includes("-")) {
                statValue = parseFloat(stats[statName]);
                if (statName.includes("Save %")) {
                    statValue *= 10.0;
                } else if (statName.includes("Breakaway save %") || statName.includes("Penalty shot save %")) {
                    statValue /= 10.0;
                }
            }

            categoryRating += statValue * weight;
        });

        if (isThreeVsThreePlayer) {
            categoryRating *= PENALTY_FACTOR;
        }

        categoryRating *= 100;
        categoryRating = Math.max(categoryRating, minOvr);
        let scaledOvr = maxOvr * (1 - Math.exp(-categoryRating / 50));
        scaledOvr = Math.max(Math.round(scaledOvr), minOvr);
        return Math.round(scaledOvr);
    };

    const calculateOverallRatingG = (stats, weights, gamesPlayed, categoryMappings, penaltyShotAttemptsPerGame) => {
        const THREE_VS_THREE_PENALTY_THRESHOLD = 0.18;
        const isThreeVsThreePlayer = penaltyShotAttemptsPerGame >= THREE_VS_THREE_PENALTY_THRESHOLD;

        let compositeOvr = 0.0;
        let allCategoryRatings = {};

        Object.keys(categoryMappings).forEach(category => {
            let rating = calculateCategoryRatingG(stats, weights, category, isThreeVsThreePlayer);
            allCategoryRatings[category] = rating;
        });

        let scalingFactor = 1.0; // Adjust as necessary based on your data
        let weightedAverage = Object.values(allCategoryRatings).reduce((a, b) => a + b, 0) / Object.keys(allCategoryRatings).length;
        compositeOvr += weightedAverage * scalingFactor;

        let avgRatings = Object.fromEntries(Object.entries(allCategoryRatings).map(([category, rating]) => [category, Math.round(rating)]));
        compositeOvr = Math.round(compositeOvr);
        let mainGameMode = isThreeVsThreePlayer ? "3s" : "6s";

        return {
            "Overall Rating": Math.round(compositeOvr),
            "Category Ratings": avgRatings,
            "Main Game Mode": mainGameMode
        };
    };

    useEffect(() => {
        const fetchPlayerData = async () => {
            setLoading(true);
            try {

                const skaterResponse = await fetch(`/api/fetch/leaderboard?league=${league}&type=skater`);
                if (!skaterResponse.ok) throw new Error('Failed to fetch skater data');
                const skaterData = await skaterResponse.json();
                const skaterHeaders = skaterData.values[0];
                const skaterRows = skaterData.values.slice(1);
                const { validHeaders: validSkaterHeaders, validRows: validSkaterRows } = filterColumns(skaterHeaders, skaterRows, 7, 62); // Columns E (index 4) to BM (index 66)

                const skaterStats = processPlayerData(validSkaterRows, validSkaterHeaders, false);

                const goalieResponse = await fetch(`/api/fetch/leaderboard?league=${league}&type=goalie`);
                if (!goalieResponse.ok) throw new Error('Failed to fetch goalie data');
                const goalieData = await goalieResponse.json();
                const goalieHeaders = goalieData.values[0];
                const goalieRows = goalieData.values.slice(1);

                const { validHeaders: validGoalieHeaders, validRows: validGoalieRows } = filterColumns(goalieHeaders, goalieRows, 56, 78); // Columns E (index 4) to BM (index 66)

                const goalieStats = processPlayerData(goalieRows, goalieHeaders, true);

                const hasSkaterStats = Object.keys(skaterStats).length > 0;
                const hasGoalieStats = Object.keys(goalieStats).length > 0;

                let teamLookupResponse = {}
                if (hasGoalieStats) {
                    teamLookupResponse = await fetch(`/api/fetch/teamLookUp?league=${league}&type=goalie`);
                } else if (hasSkaterStats) {
                    teamLookupResponse = await fetch(`/api/fetch/teamLookUp?league=${league}&type=skater`);
                }

                if (!teamLookupResponse.ok) throw new Error('Failed to fetch team look up');
                const teamLookup = await teamLookupResponse.json();
                const structuredData = structureData(teamLookup);

                const teamInfo = findUserTeam(username, structuredData);
                setPlayersTeam(teamInfo)

                if (hasGoalieStats) {
                    if (teamInfo.teamName) {
                        goalieStats['Team'] = teamInfo.teamName

                    } else {
                        goalieStats['Team'] = league

                    }
                    goalieStats['Position'] = "G"

                } else if (hasSkaterStats) {
                    if (teamInfo.teamName) {
                        skaterStats['Team'] = teamInfo.teamName

                    } else {
                        skaterStats['Team'] = league

                    }
                    const userPosition = skaterStats['Position']
                    if (userPosition == "center") {
                        skaterStats['Position'] = "C"
                    } else if (userPosition == "leftWing") {
                        skaterStats['Position'] = "LW"
                    } else if (userPosition == "rightWing") {
                        skaterStats['Position'] = "RW"
                    } else if (userPosition == "defenseMen") {
                        skaterStats['Position'] = "D"
                    }
                }
                if (hasGoalieStats && hasSkaterStats) {
                    goalieStats['Team'] = teamInfo.teamName
                    goalieStats['Position'] = "G"
                    skaterStats['Team'] = teamInfo.teamName
                    const userPosition = skaterStats['Position']
                    if (userPosition == "center") {
                        skaterStats['Position'] = "C"
                    } else if (userPosition == "leftWing") {
                        skaterStats['Position'] = "LW"
                    } else if (userPosition == "rightWing") {
                        skaterStats['Position'] = "RW"
                    } else if (userPosition == "defenseMen") {
                        skaterStats['Position'] = "D"
                    }

                }
                setSkaterStats(skaterStats);
                setGoalieStats(goalieStats);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch player data:', error);
                setLoading(false);
            }
        };

        fetchPlayerData();
    }, [username, league]);

    const filterGamesByPlayer = (data, username) => {
        // Convert the object into an array of [matchID, gameObject] pairs
        const filteredEntries = Object.entries(data).filter(([matchID, gameObject]) => {
            // Check if the username exists in skaters
            const isInSkaters = Object.values(gameObject.skaters || {}).some(
                (player) => player.Username === username
            );

            // Check if the username exists in goalies
            const isInGoalies = Object.values(gameObject.goalies || {}).some(
                (player) => player.Username === username
            );

            // Return true if the username is found in either skaters or goalies
            return isInSkaters || isInGoalies;
        });

        // Convert the filtered array back into an object
        return Object.fromEntries(filteredEntries);
    };


    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const response = await fetch(`/api/gamedata/${league}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data = await response.json();
                const filteredData = filterGamesByPlayer(data, username);
                setGameData(filteredData)
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
            }
        };

        fetchGameData();
    }, []);

    // Check if there is data for skater or goalie
    const hasSkaterStats = Object.keys(skaterStats).length > 0;
    const hasGoalieStats = Object.keys(goalieStats).length > 0;

    const overallRating = skaterStats["Overall rating"]?.["Overall Rating"] || goalieStats["Overall rating"]?.["Overall Rating"] || 0;

    const handleShareProfile = () => {
        // Copy the current URL to the clipboard
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                // You can display a message to the user indicating the link was successfully copied
                console.log('Profile link copied to clipboard!');
            })
            .catch(err => {
                // Handle any errors
                console.error('Failed to copy the profile link:', err);
            });

        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const renderStats = () => {
        if (positionState === 'goalie' && hasGoalieStats || positionState === 'skater' && !hasSkaterStats && hasGoalieStats) {
            return (
                <>
                    <PlayerInfo playerData={goalieStats} position={goalieStats.Position} overall={goalieStats["Overall rating"]?.["Overall Rating"]} league={league} />
                    <Button
                        sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}
                        onClick={handleShareProfile}
                    >
                        Share
                    </Button>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={2400} // Snackbar will close after 6000ms
                        onClose={handleCloseSnackbar}
                        TransitionComponent={state.Transition}
                        message="URL copied"
                        sx={{
                            '& .MuiSnackbarContent-root': {
                                backgroundColor: '#1976d2', // Snackbar background color
                                color: '#fff' // Text color
                            }
                        }}
                        action={
                            <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                                Close
                            </Button>
                        }
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                        <Typography variant="h4" sx={{ color: 'white' }}>Overview</Typography>
                        {(hasSkaterStats && hasGoalieStats) && <PositionToggle />}
                    </Box>
                    <Divider sx={{ borderColor: '#424549', marginTop: 1.5 }} />
                    <OverallRadarChart playerData={goalieStats} />
                    <Box elevation={3} sx={{ margin: 'auto', marginBottom: 5, maxWidth: windowWidth < 600 ? 500 : windowWidth < 900 ? '100%' : '100%', textAlign: 'center' }}>
                        <HockeyDataComponent playerStats={goalieStats} gameData={gameData} league={league} />
                    </Box>
                </>
            );
        } else if (positionState === 'skater' && hasSkaterStats || positionState === 'goalie' && !hasGoalieStats && hasSkaterStats) {
            return (
                <>
                    <PlayerInfo playerData={skaterStats} position={skaterStats.Position} overall={skaterStats["Overall rating"]?.["Overall Rating"]} league={league} />
                    <Button
                        sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}
                        onClick={handleShareProfile}
                    >
                        Share
                    </Button>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={2400} // Snackbar will close after 6000ms
                        onClose={handleCloseSnackbar}
                        TransitionComponent={state.Transition}
                        message="URL copied"
                        sx={{
                            '& .MuiSnackbarContent-root': {
                                backgroundColor: '#1976d2', // Snackbar background color
                                color: '#fff' // Text color
                            }
                        }}
                        action={
                            <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                                Close
                            </Button>
                        }
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                        <Typography variant="h4" sx={{ color: 'white' }}>Overview</Typography>
                        {(hasSkaterStats && hasGoalieStats) && <PositionToggle />}
                    </Box>
                    <Divider sx={{ borderColor: '#424549', marginTop: 1.5 }} />
                    <OverallRadarChart playerData={skaterStats} />
                    <Box elevation={3} sx={{ margin: 'auto', marginBottom: 5, maxWidth: windowWidth < 600 ? 500 : windowWidth < 900 ? '100%' : '100%', textAlign: 'center' }}>
                        <HockeyDataComponent playerStats={skaterStats} gameData={gameData} league={league} />
                    </Box>
                </>
            );
        } else {
            return (
                <Typography variant="h6" color="textSecondary">
                    No data available for the selected position.
                </Typography>
            );
        }
    };

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><PropagateLoader color="#3498db" /></div>;
    }

    return (
        <div>
            <Container>
                <Box>
                    {renderStats()}
                </Box>
            </Container>
        </div>
    );
}

export default PlayerProfile;
