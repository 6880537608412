// GameCard.js
import React from 'react';
import { Box, Paper, Typography, Grid, Card, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const GameCard = ({ gameData, league }) => {
    const navigate = useNavigate();

    const team1Id = gameData['Team 1 ID']
    const team2Id = gameData['Team 2 ID']

    const team1Score = parseInt(gameData['Team 1 Score'])
    const team2Score = parseInt(gameData['Team 2 Score'])
    const winningTeamId = team1Score > team2Score ? team1Id : team2Score > team1Score ? team2Id : null;

    // Highlight style
    const highlightStyle = {
        fontWeight: 'bold',
        color: 'white'
    };

    function formatTeamName(teamName) {
        // Split the team name to remove the league prefix and join remaining parts
        const formattedName = teamName.split(' ').slice(1).join(' ');
        
        // Convert to lowercase and replace spaces with hyphens
        return formattedName.toLowerCase().replace(/\s+/g, '-');
    }

    const loadLogo = (teamName, league) => {
        try {
            return require(`./${league}/Logos/${formatTeamName(teamName)}.png`);
        } catch (error) {
            console.error("Error loading league logo:", error);
            return null;
        }
    };

    function removeLeaguePrefix(teamName) {
        // Remove the first word (assumed to be the league prefix)
        return teamName.split(' ').slice(1).join(' ');
    }

    function adjustToEST(dateTimeString) {
        // Parse the date string
        const date = new Date(dateTimeString);
    
        // Subtract 3 hours (in milliseconds)
        date.setHours(date.getHours() - 5);
    
        // Format back to 'MM/DD/YYYY HH:mm:ss EST'
        const formattedDate = date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    
        return `${formattedDate} EST`;
    }

    const navigateToGameStats = () => {
        navigate(`/${league}/${gameData['Match ID']}`); // Navigate to game stats page
    };

    if (gameData['Team 1'] != undefined || gameData['Team 2'] != undefined) {
        return (
            <Card sx={{ borderRadius: 5, backgroundColor: '#171717', boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', minWidth: 300, margin: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <CardActionArea onClick={navigateToGameStats}>
                    <Grid container spacing={1.5} alignItems="center" sx={{ padding: 1.5 }}>
                        {/* Team 1 Row */}
                        <Typography noWrap sx={{ color: 'white', marginLeft: 2, marginTop: 1, fontWeight: 400, fontSize: '14px' }}>{adjustToEST(gameData.Date)}</Typography>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Grid item xs={2} display="flex" alignItems="center">
                                <img src={loadLogo(gameData['Team 1'], league)} alt="Team 1 Crest" style={{ width: 35, height: 35 }} />
                            </Grid>
                            <Grid item xs={8} display="flex" alignItems="center">
                                <Typography noWrap sx={team1Id === winningTeamId ? highlightStyle : { color: 'white' }}>{removeLeaguePrefix(gameData['Team 1'])}</Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                                <Typography noWrap sx={team1Id === winningTeamId ? highlightStyle : { color: 'white' }}>{gameData['Team 1 Score']}</Typography>
                            </Grid>
                        </Grid>
                        {/* Team 2 Row */}
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Grid item xs={2} display="flex" alignItems="center">
                                <img src={loadLogo(gameData['Team 2'], league)} alt="Team 2 Crest" style={{ width: 35, height: 35 }} />
                            </Grid>
                            <Grid item xs={8} display="flex" alignItems="center">
                                <Typography noWrap sx={team2Id === winningTeamId ? highlightStyle : { color: 'white' }}>{removeLeaguePrefix(gameData['Team 2'])}</Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                                <Typography noWrap sx={team2Id === winningTeamId ? highlightStyle : { color: 'white' }}>{gameData['Team 2 Score']}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>

        );
    }
};

export default GameCard;
