import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Tabs, Tab } from '@mui/material';

function GamePlayerStats({ rawGameStats, teamID1, teamID2, clubsData }) {
    const [selectedTeam, setSelectedTeam] = useState(0);
    const [gameStats, setGameStats] = useState(null);

    useEffect(() => {
        if (rawGameStats && clubsData) {
            // Add new stats to players
            const updatedStats = addNewStatsToPlayers(rawGameStats);
            setGameStats(updatedStats);
        }
    }, [rawGameStats]);

    const handleChange = (event, newValue) => {
        setSelectedTeam(newValue);
    };
    if (!gameStats) {
        return (
            <Typography sx={{ color: 'white', fontSize: '16px', textAlign: 'center', marginTop: 2 }}>
                Loading game stats...
            </Typography>
        );
    }
    const teamNames = Object.keys(gameStats.clubs); // Assuming gameStats is an object with team names as keys
    const positionMapping = {
        "center": "C",
        "leftWing": "LW",
        "rightWing": "RW",
        "defenseMen": "D",
    };

    function convertSecondsToHMS(seconds) {
        const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
        const minutes = Math.floor((seconds % 3600) / 60); // remainder from hours divided by 60
        const sec = (seconds % 60).toFixed(); // remainder from minutes is seconds

        // Output as "HH:MM:SS"
        // Use .padStart(2, '0') to add leading zeroes if the numbers are less than 10
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = sec.toString().padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    function createSvgElement(consolegen) {
        // PlayStation SVG
        if (consolegen === "ps5" || consolegen === "ps4") {
            return (
                <svg width="23" height="23" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M399.77 203c-.8-17.1-3.3-34.5-10.8-50.1a82.45 82.45 0 00-16.5-23.2 105.59 105.59 0 00-21.3-16.3c-17.1-10.2-37.5-17-84.4-31S192 64 192 64v358.3l79.9 25.7s.1-198.8.1-299.5v-3.8c0-9.3 7.5-16.8 16.1-16.8h.5c8.5 0 15.5 7.5 15.5 16.8V278c11 5.3 29.2 9.3 41.8 9.1a47.79 47.79 0 0024-5.7 49.11 49.11 0 0018.4-17.8 78.64 78.64 0 009.9-27.3c1.87-10.8 1.97-22.1 1.57-33.3zM86.67 357.8c27.4-9.8 89.3-29.5 89.3-29.5v-47.2s-76.5 24.8-111.3 37.1c-8.6 3.1-17.3 5.9-25.7 9.5-9.8 4.1-19.4 8.7-28.1 14.8a26.29 26.29 0 00-9.2 10.1 17.36 17.36 0 00-.5 13.6c2 5.1 5.8 9.3 10.1 12.6 7.8 5.9 17.1 9.5 26.4 12.2a262.42 262.42 0 0088.4 13.3c14.5-.2 36-1.9 50-4.4v-42s-11 2.5-41.3 12.5c-4.6 1.5-9.2 3.3-14 4.3a104.87 104.87 0 01-21.6 2.2c-6.5-.3-13.2-.7-19.3-3.1-2.2-1-4.6-2.2-5.5-4.6-.8-2 .3-4 1.7-5.4 2.8-2.9 6.8-4.5 10.6-6z" fill="rgb(0, 111, 205)" />
                    <path d="M 512 345.9 c -0.1 -6 -3.7 -11.2 -7.9 -15 c -7.1 -6.3 -15.9 -10.3 -24.7 -13.5 c -5.5 -1.9 -9.3 -3.3 -14.7 -5 c -25.2 -8.2 -51.9 -11.2 -78.3 -11.3 c -8 0.3 -23.1 0.5 -31 1.4 c -21.9 2.5 -67.3 15.4 -67.3 15.4 v 48.8 s 67.5 -21.6 96.5 -31.8 a 94.43 94.43 0 0 1 30.3 -4.6 c 6.5 0.2 13.2 0.7 19.4 3.1 c 2.2 0.9 4.5 2.2 5.5 4.5 c 0.9 2.6 -0.9 5 -2.9 6.5 c -4.7 3.8 -10.7 5.3 -16.2 7.4 c -41 14.5 -132.7 44.7 -132.7 44.7 v 47 s 117.2 -39.6 170.8 -58.8 c 8.9 -3.3 17.9 -6.1 26.4 -10.4 c 7.9 -4 15.8 -8.6 21.8 -15.3 a 19.74 19.74 0 0 0 5 -13.1 Z" fill="rgb(0, 111, 205)" />
                </svg>
            );
        }
        // Xbox SVG
        else if (consolegen === "xbsx" || consolegen === "xone") {
            return (
                <svg width="23" height="23" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 126.8 248.3 c 39.7 -58.6 77.9 -92.8 77.9 -92.8 s -42.1 -48.9 -92.8 -67.4 l -3.3 -0.8 A 224.13 224.13 0 0 0 77.2 391 c 0 -4.4 0.6 -70.3 49.6 -142.7 Z M 480 256 a 223.71 223.71 0 0 0 -76.6 -168.7 l -3.2 0.9 c -50.7 18.5 -92.9 67.4 -92.9 67.4 s 38.2 34.2 77.9 92.8 c 49 72.4 49.6 138.3 49.5 142.7 A 222.8 222.8 0 0 0 480 256 Z M 201.2 80.9 c 29.3 13.1 54.6 34.6 54.6 34.6 s 25.5 -21.4 54.8 -34.6 c 36.8 -16.5 64.9 -11.3 72.3 -9.5 a 224.06 224.06 0 0 0 -253.8 0 c 7.2 -1.8 35.2 -7.1 72.1 9.5 Z M 358.7 292.9 C 312.4 236 255.8 199 255.8 199 s -56.3 37 -102.7 93.9 c -39.8 48.9 -54.6 84.8 -62.6 107.8 l -1.3 4.8 a 224 224 0 0 0 333.6 0 l -1.4 -4.8 c -8 -23 -22.9 -58.9 -62.7 -107.8 Z" fill="rgb(16, 124, 16)" />
                </svg>
            );
        }
        // Default or no consolegen provided
        return null;
    }

    function addNewStatsToPlayers(gameStats) {
        const updatedGameStats = { ...gameStats }; // Create a copy to avoid mutating the original object
        const bothClubsData = clubsData; // Create a copy to avoid mutating the original object

        let Goals = 0
        let Shots = 0
        let Possession = 0
        let playerCount = 0
        let goalieCount = 0
        let savePCT = 0
        let penSavePCT = 0
        let brkwaySavePCT = 0
        for (const teamID in bothClubsData) {
            const players = bothClubsData[teamID].memberData;
            for (const playerID in players) {
                const player = players[playerID];
                Goals += parseFloat(player['Goals per game'])
                Shots += parseFloat(player['Shots per game'])
                savePCT += parseFloat(player['Save %'])
                penSavePCT += parseFloat(player['PS %'])
                brkwaySavePCT += (parseFloat(player['Breakaway save %']) * 100)
                Possession += parseFloat(player['Possession per min'])
                if (['C', 'LW', 'RW', 'D'].includes(player.Position)) {
                    playerCount += 1
                }
                if (parseInt(player['Goalie games played']) > 0){
                    goalieCount += 1
                }
            }

        }

        const avgGoals = Goals / playerCount
        const avgShots = Shots / playerCount
        const avgShotPCT = Goals / Shots
        const avgPossession = Possession / playerCount
        const avgSavePCT = savePCT / goalieCount
        const avgPenShotSavePCT = penSavePCT / goalieCount
        const avgbrkwaySavePCT = brkwaySavePCT / goalieCount

        // Iterate through each team's players
        for (const teamID in updatedGameStats.players) {
            const players = updatedGameStats.players[teamID];

            for (const playerID in players) {
                const player = players[playerID];

                if (['center', 'leftWing', 'rightWing', 'defenseMen'].includes(player.position)) {
                    player.GAR = (player.skshots > 0 && avgShots > 0)
                        ? ((parseInt(player.skgoals) / parseInt(player.skshots)) - (avgGoals / avgShots))
                        : 0; // or some other default value if shots are 0

                    const maxPossibleWAR = 1; // Increase based on empirical data or game context

                    let playerWAR = (
                        ((
                            (parseFloat(player.ratingOffense) + (parseFloat(player.ratingDefense) * .1) + parseFloat(player.ratingTeamplay)) / 3
                            + player.GAR
                        ) / 100)
                        - (parseFloat(player.skpim) / 2 - parseFloat(player.skpenaltiesdrawn)) / 100
                        - (avgShots > 0 ? (parseFloat(player.skshotpct) / avgShots) / 90 : 0)
                        + ((parseFloat(player.skpasspct) / 500) + (parseFloat(player.skassists) * .02))
                        + (parseFloat(player.skshotonnetpct) / 500)
                        - (avgShots > 0 ? ((parseFloat(player.skshotattempts) - parseFloat(player.skshots)) / avgShots) / 100 : 0)
                    );

                    playerWAR = Math.max(0, playerWAR);
                    let WARPercentage = (playerWAR / maxPossibleWAR) * 100;
                    player.WAR = Math.min(100, WARPercentage);

                    player.xG = (parseInt(player.skshots) * avgShotPCT).toFixed(2);
                    player.xGPerPossessionSec = player.xG / parseFloat(player.skpossession);
                    player.GAE = (parseFloat(player.skgoals) - player.xG).toFixed(2);
                    player.scoringChancesPerMinute = (parseFloat(player.skgoals) + parseFloat(player.skassists) + parseFloat(player.skshotattempts)) / parseFloat(player.toi);
                    player.possessionEfficiency = (parseFloat(player.skgoals) + parseFloat(player.skassists)) / (parseFloat(player.skpossession) / 60).toFixed(2);
                    player.blockingImpact = parseFloat(player.skbs) / parseFloat(player.toi); // Blocks per minute
                    player.defensiveImpact = (parseFloat(player.sktakeaways) - parseFloat(player.skgiveaways) + parseFloat(player.skinterceptions)) / parseFloat(player.toi);
                    player.netDefensiveImpact = player.defensiveImpact + player.blockingImpact;                    
                    player.ppImpact = parseFloat(player.skppg) / player.xG; // power play efficiency relative to expected goals
                } else {
                    const maxPossibleGWAR = 1;
                    const goalsToWinsConversion = 5; // Example conversion, needs calibration

                    const shotsFaced = parseInt(player.glshots);
                    const goalsAllowed = parseInt(player.glga);
                    const breakawayShotsFaced = parseInt(player.glbrkshots);
                    const breakawaySaves = parseInt(player.glbrksaves);
                    
                    const brkEGA = breakawayShotsFaced * (1 - avgbrkwaySavePCT / 100);
                    const brkGSAA = breakawaySaves - brkEGA;
                    const savePct = parseFloat(player.glsavepct) * 100;
                    const penSavePct = parseFloat(player.glpensavepct) * 100;

                    player.GSAA = (shotsFaced * (1 - (avgSavePCT/100))) - goalsAllowed;
                    player.EGA = (shotsFaced * (1 - (avgSavePCT/100)))

                    // Here's a more nuanced GWAR:
                    let playerGWAR = (
                        ((parseFloat(player.ratingOffense) + parseFloat(player.ratingDefense) + parseFloat(player.ratingTeamplay)) / 3) +
                        (player.GSAA / goalsToWinsConversion) +  // From regular shots GSAA contribution
                        (brkGSAA / goalsToWinsConversion) +  // From breakaway GSAA contribution
                        ((parseFloat(player.glsavepct) - avgSavePCT / 100) * shotsFaced) / goalsToWinsConversion +  // Impact of regular save percentage
                        ((parseFloat(player.glbrksavepct) - avgbrkwaySavePCT / 100) * breakawayShotsFaced) / goalsToWinsConversion +
                        ((penSavePct - avgPenShotSavePCT / 100) * parseInt(player.glpenshots)) / goalsToWinsConversion // Impact of breakaway save percentage
                    );

                    // Normalize GWAR for a single game:
                    player.GWAR = Math.max(0.1, playerGWAR);
                    let GWARPercentage = (player.GWAR / maxPossibleGWAR);
                    player.GWAR = Math.min(100, GWARPercentage);

                }
                player.huh = "huh"
            }
        }

        return updatedGameStats;
    }



    const isGoalie = (player) => player.position === 'goalie';

    if (!gameStats) return null;

    return (
        <Paper sx={{ height: 'fit-content', overflow: 'auto', backgroundColor: 'transparent' }}>
            <Tabs
                value={selectedTeam}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                {teamNames.map((team, index) => (
                    <Tab key={index} label={gameStats.clubs[team].details.name} sx={{ color: 'white' }} />
                ))}
            </Tabs>
            {teamNames.map((team, index) => (
                <TableContainer key={index} component={Paper} sx={{
                    display: selectedTeam === index ? 'block' : 'none', backgroundColor: 'transparent', marginTop: 5, '&::-webkit-scrollbar': {
                        width: '0px'
                    },

                    /* Track */
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 5px #121212',
                        borderRadius: '10px'
                    },

                    /* Handle */
                    '&::-webkit-scrollbar-thumb': {
                        background: '#1f1f1f',
                        borderRadius: '10px'
                    },

                    /* Handle on hover */
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#303030'
                    }
                }}>
                    <Table stickyHeader aria-label="player stats table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" sx={{ color: 'white', fontSize: '16px', position: 'sticky', left: 0, zIndex: 5 }}>User</TableCell>
                                <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Plat</TableCell>
                                <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Pos</TableCell>
                                <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Off</TableCell>
                                <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Def</TableCell>
                                <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Team</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>G</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>A</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>P</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>S</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>S%</TableCell>
                                <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>SA</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>+/-</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Ps</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>PA</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Ps%</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>FO%</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Poss</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>PIM</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Hits</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Ints</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Tkws</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Gvws</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(gameStats.players[team]).filter(player => !isGoalie(player)).map((player, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px', position: 'sticky', left: 0, zIndex: 5, backgroundColor: '#121212' }}>{player.playername}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{createSvgElement(player.clientPlatform)}</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>{positionMapping[player.position] || player.position}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.ratingOffense}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.ratingDefense}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.ratingTeamplay}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skgoals}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skassists}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{parseInt(player.skgoals, 10) + parseInt(player.skassists, 10)}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skshots}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skshotpct}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skshotattempts}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skplusmin}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skpasses}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skpassattempts}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skpasspct}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skfopct}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{convertSecondsToHMS(player.skpossession)}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skpim}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skhits}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skinterceptions}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.sktakeaways}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.skgiveaways}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {Object.values(gameStats.players[team]).some(isGoalie) && (
                        <Table stickyHeader aria-label="goalie stats table" sx={{ marginTop: 2 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px', position: 'sticky', left: 0, zIndex: 5, backgroundColor: '#121212' }}>User</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Plat</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Pos</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>GAA</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Save%</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Saves</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Brks</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Brk SVs</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Brk SV%</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Pen STs</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Pen SVs</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Pen SV%</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Dive SVS</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>Pokes</TableCell>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>SOPs</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(gameStats.players[team]).filter(isGoalie).map((player, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={{ color: 'white', fontSize: '16px', position: 'sticky', left: 0, zIndex: 5, backgroundColor: '#121212' }}>{player.playername}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{createSvgElement(player.clientPlatform)}</TableCell>
                                        <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>G</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.glgaa}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.glsavepct}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.glsaves}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.glbrkshots}</TableCell>
                                        <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>{player.glbrksaves}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.glbrksavepct}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.glpenshots}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.glpensaves}</TableCell>
                                        <TableCell align="left" sx={{ color: 'white', fontSize: '16px' }}>{player.glpensavepct}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.gldsaves}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.glpokechecks}</TableCell>
                                        <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{player.glsoperiods}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
            ))}
        </Paper>
    );
}

export default GamePlayerStats;
