import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import SharedProgressBar from './SharedProgressBar';
function GameStats({ gameData, color1, color2 }) {
    if (!gameData) return null;

    return (
        <Box sx={{ marginTop: 4 }}>
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1_deserved_score}
                team2Stats={gameData.game_stats.team2_deserved_score}
                color1={color1}
                color2={color2}
                label="Deserve to Win"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1.Shots}
                team2Stats={gameData.game_stats.team2.Shots}
                color1={color1}
                color2={color2}
                label="Shots on Goal"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1['Faceoff Wins']}
                team2Stats={gameData.game_stats.team2['Faceoff Wins']}
                color1={color1}
                color2={color2}
                label="Faceoff Wins"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1['Possession (secs)']}
                team2Stats={gameData.game_stats.team2['Possession (secs)']}
                color1={color1}
                color2={color2}
                label="Possession"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1['Pass %']}
                team2Stats={gameData.game_stats.team2['Pass %']}
                color1={color1}
                color2={color2}
                label="Pass %"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1['Hits']}
                team2Stats={gameData.game_stats.team2['Hits']}
                color1={color1}
                color2={color2}
                label="Hits"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1['Interceptions']}
                team2Stats={gameData.game_stats.team2['Interceptions']}
                color1={color1}
                color2={color2}
                label="Interceptions"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1['Takeaways']}
                team2Stats={gameData.game_stats.team2['Takeaways']}
                color1={color1}
                color2={color2}
                label="Takeaways"
            />
            <SharedProgressBar
                team1Stats={gameData.game_stats.team1['PIMs']}
                team2Stats={gameData.game_stats.team2['PIMs']}
                color1={color1}
                color2={color2}
                label="PIM"
            />
        </Box>
    );
}

export default GameStats;
