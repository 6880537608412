import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { useStats } from '../../Contexts/StatsContext';

const ScrollableTable = ({ playerData }) => {
    const { state } = useStats();
    const { positionState } = state;
    const recentGameStats = playerData.playerRecentData.playerGameStats

    function convertSecondsToHMS(seconds) {
        const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
        const minutes = Math.floor((seconds % 3600) / 60); // remainder from hours divided by 60
        const sec = (seconds % 60).toFixed(); // remainder from minutes is seconds

        // Output as "HH:MM:SS"
        // Use .padStart(2, '0') to add leading zeroes if the numbers are less than 10
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = sec.toString().padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }
    if (recentGameStats) {

        if (positionState === 'skater') {
            return (
                <TableContainer component={Paper} style={{ height: 'fit-content', overflow: 'auto', backgroundColor: 'transparent' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'white', fontSize: '16px' }}>Date</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '16px' }}>Opponent</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Res</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '16px' }}>Pos</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>G</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>A</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>P</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>S</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>+/-</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Poss</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>PIM</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Hits</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Ints</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Tkws</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Gvws</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recentGameStats.filter(game => game != null).map((game) => (
                                <TableRow key={game.gameInfo.matchID} sx={{ color: 'white', fontSize: '16px' }}>
                                    <TableCell align="left" sx={{ color: 'white', fontSize: '16px', width: '1%', whiteSpace: 'nowrap' }}>{game.gameInfo.date}</TableCell>
                                    <TableCell component="th" scope="row" sx={{ color: 'white', fontSize: '16px', minWidth: '180px' }}>
                                        VS {game.gameInfo.opponent}
                                    </TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.gameInfo.result}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.position}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.skgoals}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.skassists}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{parseInt(game.skgoals, 10) + parseInt(game.skassists, 10)}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.skshots}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.skplusmin}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{convertSecondsToHMS(game.skpossession)}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.skpim}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.skhits}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.skinterceptions}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.sktakeaways}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.skgiveaways}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else if (positionState === 'goalie') {
            return (
                <TableContainer component={Paper} style={{ height: 'fit-content', overflow: 'auto', backgroundColor: 'transparent' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'white', fontSize: '16px' }}>Date</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '16px' }}>Opponent</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>Res</TableCell>
                                <TableCell sx={{ color: 'white', fontSize: '16px' }}>Pos</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>SA</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>GA</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>SV%</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>BRKSV</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>BRKSV%</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>PENSV</TableCell>
                                <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>PENSV%</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recentGameStats.filter(game => game != null).map((game) => (

                                <TableRow key={game.gameInfo.matchID} sx={{ color: 'white', fontSize: '16px' }}>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px', width: '1%', whiteSpace: 'nowrap' }}>{game.gameInfo.date}</TableCell>
                                    <TableCell component="th" scope="row" sx={{ color: 'white', fontSize: '16px', minWidth: '180px' }}>
                                        VS {game.gameInfo.opponent}
                                    </TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.gameInfo.result}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.position}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.glshots}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.glga}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.glsavepct}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.glbrksaves}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.glbrksavepct}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.glpensaves}</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontSize: '16px' }}>{game.glpensavepct}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    } else {
        return (
            <Typography sx={{ color: 'white', fontSize: '18px', textAlign: 'center', margin: 'auto', marginTop: 5 }}>No recent games found</Typography>
        )
    }
};

export default ScrollableTable;
