// GameCarousel.js
import React, { useState, useEffect, useRef } from 'react';
import GameCard from './GameCard';
import { Box, Typography } from '@mui/material';

const GameCarousel = ({ gamesData, league }) => {
    const [sortedGames, setSortedGames] = useState([]);
    const carouselRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        // Sort by date in descending order and reverse to have the most recent first
        const sorted = Object.values(gamesData).sort((a, b) => new Date(a.game.Date) - new Date(b.game.Date));
        setSortedGames(sorted);

        // Scroll to the end on mount to display the most recent game at the start
        if (carouselRef.current) {
            carouselRef.current.scrollLeft = carouselRef.current.scrollWidth;
        }
    }, [gamesData]);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - carouselRef.current.offsetLeft);
        setScrollLeft(carouselRef.current.scrollLeft);
    };

    const handleMouseLeave = () => setIsDragging(false);

    const handleMouseUp = () => setIsDragging(false);

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 1.5; // Adjust scrolling speed
        carouselRef.current.scrollLeft = scrollLeft + walk; // Reverse the scroll direction by using addition
    };

    return (
        <Box sx={{ width: '100%', overflow: 'hidden', marginTop: 4 }}>
            <Typography sx={{color:'white'}}>Recent games</Typography>
            <Box
                ref={carouselRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                sx={{
                    display: 'flex',
                    cursor: 'grab',
                    overflowX: 'auto',
                    padding: 2,
                    '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar
                }}
            >
                {sortedGames.map((gameData, index) => (
                    <GameCard key={index} gameData={gameData.game} league={league} />
                ))}
            </Box>
        </Box>
    );
};

export default GameCarousel;
