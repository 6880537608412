import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Typography, Card, CardContent, Grid, Divider, Table, TableContainer, TableBody, TableRow, TableCell, TableHead, Paper } from '@mui/material';
import PositionalProgressBar from './PositionalProgressBar'
import OverallRatingBar from './OverallRatingBar'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveIcon from '@mui/icons-material/Remove';
import { useStats } from '../../Contexts/StatsContext';
import { useLocation, useNavigate } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
            style={{ height: '100%', overflow: 'auto', minHeight: windowWidth < 600 ? '200px' : windowWidth < 900 ? '100%' : '100%' }} // You might merge this with `...other` if passing styles via props
        >
            {value === index && (
                <Box sx={{ width: '100%', marginTop: 2 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const StatsIndicator = ({ recent, career }) => {
    // Replace NaN or Infinity with 0
    const validRecent = isFinite(recent) ? recent : 0;
    const validCareer = isFinite(career) ? career : 0;

    const goodPerformance = validRecent > validCareer;
    const badPerformance = validRecent < validCareer;
    const neutralPerformance = validRecent === validCareer;

    return (
        <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
            {goodPerformance ? (
                <ArrowUpwardIcon sx={{ color: 'green', fontSize: '22px' }} />
            ) : badPerformance ? (
                <ArrowDownwardIcon sx={{ color: 'red', fontSize: '22px' }} />
            ) : neutralPerformance ? ( // This checks specifically for equality
                <RemoveIcon sx={{ color: 'yellow', fontSize: '22px' }} />
            ) : ( // This checks specifically for equality
                <RemoveIcon sx={{ color: 'yellow', fontSize: '22px' }} />
            ) // Just in case, though it should never be reached with the current logic
            }
        </Typography>
    );
};

function displayStat(label, stat) {
    const excludeList = ['Possession per game', 'Possession time']
    if (label == 'Possession per game') {
        return stat.substring(3, 8)
    } 
    else if (label == 'Possession time') {
        return stat
    }
    else if (label == 'Dekes per game') {
        return stat
    }
    else if (!isFinite(stat)) {
        return 0
    }
    else {
        return stat
    }
}

function displayRecentStat(label, stat) {
    const excludeList = ['Possession per game', 'Possession time']
    if (label == 'Possession per game') {
        return stat.substring(3, 8)
    } 
    else if (label == 'Possession time') {
        return stat
    }
    else if (label == 'Dekes per game') {
        return stat
    }
    else if (label.includes('%')) {
        return '-'
    }
    else if (!isFinite(stat)) {
        return 0
    }
    else {
        return stat
    }
}


const RecentStatsTable = ({ stats }) => (
    <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem' }}>
        <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ color: 'white' }}>Stats</TableCell>
                    <TableCell sx={{ color: 'white' }} align="right">Career</TableCell>
                    <TableCell sx={{ color: 'white' }} align="right">Recent</TableCell>
                    <TableCell sx={{ color: 'white' }} align="right"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {stats.map((stat, index) => (
                    <TableRow
                        key={stat.name}
                        sx={{
                            '&:last-of-type td, &:last-of-type th': {
                                borderBottom: 'none', // Remove border bottom for the last row
                            },
                        }}
                    >
                        <TableCell component="th" scope="row" sx={{ color: 'white' }}>{stat.name}</TableCell>
                        <TableCell align="right" sx={{ color: 'white' }}>{displayStat(stat.name, stat.career)}</TableCell>
                        <TableCell align="right" sx={{ color: 'white' }}>{displayStat(stat.name, stat.recent)}</TableCell>
                        <TableCell align="right"><StatsIndicator recent={stat.recent} career={stat.career} /></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);



const HockeyDataComponent = ({ playerStats }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const { state } = useStats();
    const { positionState } = state;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    function createData(name, total, per_game) {
        return { name, total, per_game };
    }

    const OffensiveStats = [
        createData('Goals', playerStats.Goals, playerStats['Goals per game']),
        createData('Assists', playerStats.Assists, playerStats['Assists per game']),
        createData('Points', playerStats.Points, playerStats['PPG']),
        createData('GWGs', playerStats.GWGs, (parseInt(playerStats.GWGs) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Hattricks', playerStats.Hattricks, (parseInt(playerStats.Hattricks) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('+/-', playerStats['+/-'], playerStats['+/- per game']),
    ];

    const Shooting = [
        createData('Goals', playerStats.Goals, playerStats['Goals per game']),
        createData('Shots', playerStats.Shots, playerStats['Shots per game']),
        createData('Shot %', playerStats['Shot %'], '-'),
        createData('Shots on net %', playerStats['Shots on net %'], '-'),
        createData('Breakaway goals', playerStats.skbrkgoals, (parseInt(playerStats.skbrkgoals)/parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Breakaway attempts', playerStats.skbreakaways, (parseInt(playerStats.skbreakaways)/parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Breakaway %', playerStats['Breakaway %'], '-'),
        createData('PS goals', playerStats.penaltyshotgoals, (parseInt(playerStats.penaltyshotgoals)/parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('PS attempts', playerStats.skpenaltyattempts, (parseInt(playerStats.skpenaltyattempts)/parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('PS %', playerStats['PS %'], '-'),
    ];

    const Passing = [
        createData('Assists', playerStats.Assists, playerStats['Assists per game']),
        createData('Passes', playerStats.Passes, playerStats['Passes per game']),
        createData('Pass attempts', playerStats['Pass attempts'], playerStats['Pass attempts per game']),
        createData('Pass %', playerStats['Pass %'], '-'),
        createData('Saucer passes', playerStats['Saucer passes'], playerStats['Sauce per game']),
        createData('Giveaways', playerStats.Giveaways, playerStats['Giveaways per game']),
    ];

    const Handeye = [
        createData('Dekes', playerStats.Dekes, playerStats['Dekes per game']),
        createData('Faceoff %', playerStats['FO %'], '-'),
        createData('Faceoff wins', playerStats['Faceoff wins'], (parseInt(playerStats['Faceoff wins']) / parseInt(playerStats['Faceoffs'])).toFixed(2)),
        createData('Faceoff losses', playerStats['Faceoff losses'], (parseInt(playerStats['Faceoff losses']) / parseInt(playerStats['Faceoffs'])).toFixed(2)),
        createData('Possession time', playerStats['Possession time'], (playerStats['Possession per game']).substring(3, 8)),
        createData('Offsides', playerStats.Offsides, playerStats['Offsides per game']),
        createData('Deflections', playerStats.Deflections, playerStats['Deflections per game']),
    ];

    const Physicality = [
        createData('Hits', playerStats.Hits, playerStats['Hits per game']),
        createData('Penalties drawn', playerStats['Penalties Drawn'], playerStats['Penalties drawn per game']),
        createData('PIM', playerStats['PIM'], playerStats['PIMS per game']),
        createData('PIM (Non-fight)', (parseInt(playerStats['PIM']) - (parseInt(playerStats['Fights']) * 5)), parseFloat(playerStats['PIMs per game minus fights']).toFixed(2)),

        createData('Fights', playerStats['Fights'], (parseInt(playerStats['Fights']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Fights won', playerStats['Fights Won'], (parseInt(playerStats['Fights Won']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Fights won %', (parseFloat(playerStats['Fights Won %']) * 100).toFixed(1) , '-'),
    ];

    const DefensiveStats = [
        createData('Hits', playerStats.Hits, playerStats['Hits per game']),
        createData('Interceptions', playerStats.Interceptions, playerStats['Interceptions per game']),
        createData('Blocked shots', playerStats['Blocked shots'], playerStats['Blocked shots per game']),
        createData('Takeaways', playerStats.Takeaways, playerStats['Takeaways per game']),
        createData('PK zone clears', playerStats['PK zone clears'], (parseInt(playerStats['PK zone clears']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('GTIR', ((parseInt(playerStats.Interceptions) + parseInt(playerStats.Takeaways)) - parseInt(playerStats.Giveaways)), '-'),

    ];

    const ZoneAbility = [
        createData('Goals', playerStats['Xfactor zone goals'], (parseInt(playerStats['Xfactor zone goals']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Assists', playerStats['Xfactor zone assists'], (parseInt(playerStats['Xfactor zone assists']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Hits', playerStats['Xfactor zone hits'], (parseInt(playerStats['Xfactor zone hits']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Stick checks', playerStats['Xfactor zone stick checks'], (parseInt(playerStats['Xfactor zone stick checks']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Times used', playerStats['Xfactor zone times used'], (parseInt(playerStats['Xfactor zone times used']) / parseInt(playerStats['Games Played'])).toFixed(1)),
    ];

    const SuperstarAbility = [
        createData('Goals', playerStats['Xfactor super-star goals'], (parseInt(playerStats['Xfactor super-star goals']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Assists', playerStats['Xfactor super-star assists'], (parseInt(playerStats['Xfactor super-star assists']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Hits', playerStats['Xfactor super-star hits'], (parseInt(playerStats['Xfactor super-star hits']) / parseInt(playerStats['Games Played'])).toFixed(1)),
        createData('Stick checks', playerStats['Xfactor super-star stick checks'], (parseInt(playerStats['Xfactor super-star stick checks']) / parseInt(playerStats['Games Played'])).toFixed(1)),

    ];

    let recentDefensiveStats
    let recentShootingStats
    let recentPassingStats
    let recentHandeyeStats
    let recentGoalieStats
    let recentGoalieStats2
    let recentGoalieStats3
    if (playerStats.playerRecentData != "noDataFound: false") {

        recentDefensiveStats = [
            { name: "+/- per game", recent: parseFloat(playerStats.playerRecentData.averages['+/- per game'],).toFixed(2), career: parseFloat(playerStats['+/- per game']).toFixed(2), },
            { name: "Hits per game", recent: parseFloat(playerStats.playerRecentData.averages['Hits per game'],).toFixed(2), career: parseFloat(playerStats['Hits per game']).toFixed(2), },
            { name: "Blocks per game", recent: parseFloat(playerStats.playerRecentData.averages['Blocked shots per game'],).toFixed(2), career: parseFloat(playerStats['Blocked shots per game']).toFixed(2), },
            { name: "Interceptions per game", recent: parseFloat(playerStats.playerRecentData.averages['Interceptions per game'],).toFixed(2), career: parseFloat(playerStats['Interceptions per game']).toFixed(2), },
            { name: "Takeaways per game", recent: parseFloat(playerStats.playerRecentData.averages['Takeaways per game'],).toFixed(2), career: parseFloat(playerStats['Takeaways per game']).toFixed(2), },
            { name: "PK zone clears per game", recent: parseFloat(playerStats.playerRecentData.averages['PK zone clears per game'],).toFixed(2), career: (parseInt(playerStats['PK zone clears']) / parseInt(playerStats['Games Played'])).toFixed(1) },
        ];

        recentShootingStats = [
            { name: "Goals per game", recent: parseFloat(playerStats.playerRecentData.averages['Goals per game'],).toFixed(2), career: parseFloat(playerStats['Goals per game']).toFixed(2), },
            { name: "Points per game", recent: parseFloat(playerStats.playerRecentData.averages['PPG'],).toFixed(2), career: parseFloat(playerStats['PPG']).toFixed(2), },
            { name: "GWGs per game", recent: (parseInt(playerStats.playerRecentData.averages['Game winning goals']) / parseInt(playerStats.playerRecentData.averages['Games Played'])).toFixed(1), career: (parseInt(playerStats.GWGs) / parseInt(playerStats['Games Played'])).toFixed(1) },
            { name: "Shots per game", recent: parseFloat(playerStats.playerRecentData.averages['Shots per game'],).toFixed(2), career: parseFloat(playerStats['Shots per game']).toFixed(2), },
            { name: "Shot %", recent: parseFloat(playerStats.playerRecentData.averages['Shot %'],).toFixed(2), career: parseFloat(playerStats['Shot %']).toFixed(2), },
            { name: "Shots on net %", recent: parseFloat(playerStats.playerRecentData.averages['Shots on net %'],).toFixed(2), career: parseFloat(playerStats['Shots on net %']).toFixed(2), },
            { name: "PS %", recent: parseFloat(playerStats.playerRecentData.averages['PS %'],).toFixed(2), career: parseFloat(playerStats['PS %']).toFixed(2), },
            { name: "Brkwy %", recent: parseFloat(playerStats.playerRecentData.averages['Breakaway %'],).toFixed(2), career: parseFloat(playerStats['Breakaway %']).toFixed(2), },
        ];

        recentPassingStats = [
            { name: "Assists per game", recent: parseFloat(playerStats.playerRecentData.averages['Assists per game'],).toFixed(2), career: parseFloat(playerStats['Assists per game']).toFixed(2), },
            { name: "Passes per game", recent: parseFloat(playerStats.playerRecentData.averages['Passes per game'],).toFixed(2), career: parseFloat(playerStats['Passes per game']).toFixed(2), },
            { name: "Pass %", recent: parseFloat(playerStats.playerRecentData.averages['Pass %'],).toFixed(2), career: parseFloat(playerStats['Pass %']).toFixed(2), },
            { name: "Saucer passes per game", recent: (parseInt(playerStats.playerRecentData.averages['Saucer passes']) / parseInt(playerStats.playerRecentData.averages['Games Played'])).toFixed(1), career: parseFloat(playerStats['Sauce per game']).toFixed(2), },
            { name: "Pass attempts per game", recent: parseFloat(playerStats.playerRecentData.averages['Pass attempts per game'],).toFixed(2), career: parseFloat(playerStats['Pass attempts per game']).toFixed(2), },
            { name: "Giveaways per game", recent: parseFloat(playerStats.playerRecentData.averages['Giveaways per game'],).toFixed(2), career: parseFloat(playerStats['Giveaways per game']).toFixed(2), },
        ];

        recentHandeyeStats = [
            { name: "Faceoff %", recent: parseFloat(playerStats.playerRecentData.averages['FO %'],).toFixed(2), career: parseFloat(playerStats['FO %']).toFixed(2), },
            { name: "Offsides per game", recent: parseFloat(playerStats.playerRecentData.averages['Offsides per game'],).toFixed(2), career: parseFloat(playerStats['Offsides per game']).toFixed(2), },
            { name: "Deflections per game", recent: parseFloat(playerStats.playerRecentData.averages['Deflections per game'],).toFixed(2), career: parseFloat(playerStats['Deflections per game']).toFixed(2), },
            { name: "Possession per game", recent: parseFloat(playerStats.playerRecentData.averages['Possession per game'],).toFixed(2), career: parseFloat(playerStats['Possession per game']).toFixed(2), },
            { name: "Dekes per game", recent: 'Unavailable', career: parseFloat(playerStats['Dekes per game']).toFixed(2), },
        ];

        recentGoalieStats = [
            { name: "Saves per game", recent: parseFloat(playerStats.playerRecentData.averages['Saves per game'],).toFixed(2), career: parseFloat(playerStats['Saves per game']).toFixed(2), },
            { name: "Shots against", recent: (parseInt(playerStats.playerRecentData.averages['Shots against']) / parseInt(playerStats.playerRecentData.averages['G Gps'])).toFixed(2), career: (parseInt(playerStats['Goalie shots']) / parseInt(playerStats['Goalie games played'])).toFixed(0) },
            { name: "Pokechecks per game", recent: (parseInt(playerStats.playerRecentData.averages['Pokechecks']) / parseInt(playerStats['G Gps'])).toFixed(2), career: (parseInt(playerStats['Goalie pokechecks']) / parseInt(playerStats['Goalie games played'])).toFixed(0) },
            { name: "Diving saves per game", recent: (parseInt(playerStats.playerRecentData.averages['Pokechecks']) / parseInt(playerStats['G Gps'])).toFixed(2), career: (parseInt(playerStats['Goalie pokechecks']) / parseInt(playerStats['Goalie games played'])).toFixed(0) },

        ];

        recentGoalieStats2 = [
            { name: "Breakaway saves", recent: parseFloat(playerStats.playerRecentData.averages['Breakaway saves'],).toFixed(2), career: parseFloat(playerStats['Breakaway saves']).toFixed(2), },
            { name: "Breakaway saves per game", recent: (parseInt(playerStats.playerRecentData.averages['Breakaway saves']) / parseInt(playerStats['G Gps'])).toFixed(2), career: (parseInt(playerStats['Breakaway saves']) / parseInt(playerStats['Goalie games played'])).toFixed(2) },
            { name: "Breakaway save %", recent: parseFloat(playerStats.playerRecentData.averages['Breakaway %'],).toFixed(2), career: parseFloat(playerStats['Breakaway save %']).toFixed(2), },
            { name: "Penalty shot saves", recent: parseFloat(playerStats.playerRecentData.averages['Penalty shot saves'],).toFixed(2), career: parseFloat(playerStats['Penalty shot saves']).toFixed(2), },
            { name: "Penalty shot saves per game", recent: (parseInt(playerStats.playerRecentData.averages['Penalty shot saves']) / parseInt(playerStats['G Gps'])).toFixed(2), career: (parseInt(playerStats['Penalty shot saves']) / parseInt(playerStats['Goalie games played'])).toFixed(2) },
            { name: "Penalty shot save %", recent: parseFloat(playerStats.playerRecentData.averages['PS %'],).toFixed(2), career: parseFloat(playerStats['Penalty shot save %']).toFixed(2), },
            { name: "Shutout periods", recent: parseFloat(playerStats.playerRecentData.averages['Shutout periods'],).toFixed(2), career: parseFloat(playerStats['Shutout periods']).toFixed(2), },

        ];

        recentGoalieStats3 = [
            { name: "GAA", recent: parseFloat(playerStats.playerRecentData.averages['GAA'],).toFixed(2), career: parseFloat(playerStats['Goals allowed']).toFixed(2), },
            { name: "Save %", recent: parseFloat(playerStats.playerRecentData.averages['Save %'],).toFixed(2), career: parseFloat(playerStats['Save %']).toFixed(2), },

        ];

    }

    const GoalieStats = [
        createData('Saves', playerStats['Goalie saves'], playerStats['Saves per game']),
        createData('Shots against', playerStats['Goalie shots'], (parseInt(playerStats['Goalie shots']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Pokechecks', playerStats['Goalie pokechecks'], playerStats['Poke checks per game']),
        createData('Diving saves', playerStats['Diving saves'], playerStats['Diving saves per game']),
    ];

    const GoalieStats2 = [
        createData('Breakaway saves', playerStats['Breakaway saves'], (parseInt(playerStats['Breakaway saves']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Breakaway shots against', playerStats['Breakaway shots against'], (parseInt(playerStats['Breakaway shots against']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Breakaway save %', playerStats['Breakaway save %'], '-'),
        createData('Penalty shot saves', playerStats['Penalty shot saves'], (parseInt(playerStats['Penalty shot saves']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Penalty shots against', playerStats['Penalty shots against'], (parseInt(playerStats['Penalty shots against']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Penalty shot save %', playerStats['Penalty shot save %'], '-'),
        createData('Shutouts', playerStats['Shutouts'], (parseInt(playerStats['Shutouts']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
        createData('Shutout periods', playerStats['Shutout periods'], (parseInt(playerStats['Shutout periods']) / parseInt(playerStats['Goalie games played'])).toFixed(2)),
    ];

    const GoalieStats3 = [
        createData('Goals allowed', playerStats['Goals allowed'], playerStats.GAA),
        createData('Save %', playerStats['Save %'], '-'),
    ];

    const GoalieXfactorAbility = [
        createData('Saves', playerStats['Xfactor saves'], (parseInt(playerStats['Xfactor saves']) / parseInt(playerStats['Goalie games played'])).toFixed(1)),
        createData('Zone ability saves', playerStats['Xfactor zone ability saves'], (parseInt(playerStats['Xfactor zone ability saves']) / parseInt(playerStats['Goalie games played'])).toFixed(1)),
        createData('Superstar ability saves', playerStats['Xfactor superstar ability saves'], (parseInt(playerStats['Xfactor superstar ability saves']) / parseInt(playerStats['Goalie games played'])).toFixed(1)),
    ];

    const gamesPlayedObj = { 'C': parseInt(playerStats['C Gps']), 'LW': parseInt(playerStats['LW Gps']), 'RW': parseInt(playerStats['RW Gps']), 'D': parseInt(playerStats['D Gps']), 'G': parseInt(playerStats['Goalie games played']) }

    let recentSkaterStats
    let recentGoalieStatsHUH
    if (playerStats.playerRecentData != "noDataFound: false") {
        recentSkaterStats = (
            <TabPanel value={tabIndex} index={1}>
                
                <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography sx={{ color: 'white', marginBottom: 2 }}>Games played: {playerStats.playerRecentData.averages['Games Played']}</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Overall Rating'])} category={'OVR'} />
                            <Divider sx={{ borderColor: '#424549', marginTop: 5 }} />

                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 5 }}>Recent Shooting Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['SHT'])} category={'SHT'} />
                            <RecentStatsTable stats={recentShootingStats} />
                        </Card>
                    </Grid>
                    <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 2 }} />

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 3 }}>Recent Passing Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['PSS'])} category={'PSS'} />
                            <RecentStatsTable stats={recentPassingStats} />
                        </Card>
                    </Grid>
                    <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 2 }} />

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 3 }}>Recent Handeye Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['HND'])} category={'HND'} />
                            <RecentStatsTable stats={recentHandeyeStats} />
                        </Card>
                    </Grid>
                    <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 2 }} />

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 3 }}>Recent Defense Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['DEF'])} category={'DEF'} />
                            <Box sx={{marginBottom: 4}}></Box>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Category Ratings']['PHS'])} category={'PHS'} />
                            <RecentStatsTable stats={recentDefensiveStats} />
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>
        )

        recentGoalieStatsHUH = (
            <TabPanel value={tabIndex} index={1}>
                <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography sx={{ color: 'white', marginBottom: 3 }}>Games played: {playerStats.playerRecentData.averages['Games Played']}</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['skaterRatings']['Overall Rating'])} category={'OVR'} />
                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 5 }}>Recent Consistency Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['goalieRatings']['Category Ratings']['CON'])} category={'CON'} />
                            <RecentStatsTable stats={recentGoalieStats3} />
                        </Card>
                    </Grid>
                    <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 2 }} />

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 5 }}>Recent Reflexes Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['goalieRatings']['Category Ratings']['REF'])} category={'REF'} />
                            <RecentStatsTable stats={recentGoalieStats} />
                        </Card>
                    </Grid>
                    <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 2 }} />

                    <Grid item xs={12} md={6}>
                        <Card sx={{ backgroundColor: 'transparent' }}>
                            <Typography variant="h6" sx={{ color: 'white', marginBottom: 1, marginTop: 5 }}>Recent Clutchness Stats</Typography>
                            <OverallRatingBar rating={parseInt(playerStats.playerRecentData.averages['Overall ratings']['goalieRatings']['Category Ratings']['CLU'])} category={'CLU'} />
                            <RecentStatsTable stats={recentGoalieStats2} />
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>
        )
    } else {
        recentSkaterStats = (
            <TabPanel value={tabIndex} index={1}>
                <Typography sx={{ color: 'white', fontSize: '18px', marginTop: 5 }}>No recent game data found</Typography>
            </TabPanel>
        )

        recentGoalieStatsHUH = (
            <TabPanel value={tabIndex} index={1}>
                <Typography sx={{ color: 'white', fontSize: '18px', marginTop: 5 }}>No recent game data found</Typography>
            </TabPanel>
        )
    }

    if (positionState === 'skater') {
        return (
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="Hockey stats tabs" variant="scrollable">
                        <Tab label="Career Stats" sx={{ color: 'white' }} />
                        <Tab label="Recent Games" sx={{ color: 'white' }} />
                        <Tab label="Shooting" sx={{ color: 'white' }} />
                        <Tab label="Passing" sx={{ color: 'white' }} />
                        <Tab label="Handeye" sx={{ color: 'white' }} />
                        <Tab label="Physicality" sx={{ color: 'white' }} />
                        <Tab label="Defense" sx={{ color: 'white' }} />
                        <Tab label="Zone ability" sx={{ color: 'white' }} />
                        <Tab label="Superstar ability" sx={{ color: 'white' }} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6} >
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Offensive Totals</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <Typography sx={{ color: 'white' }}>Games played: {playerStats['Games Played']}</Typography>
                                <PositionalProgressBar totalGames={parseInt(playerStats['Games Played'])} gamesByPosition={gamesPlayedObj} />
                                <Box sx={{ marginTop: 3 }}></Box>
                                <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Overall Rating'])} category={'OVR'} />
                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {OffensiveStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                        <Divider sx={{ borderColor: '#424549', marginTop: 3 }} />
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 2, mt: 3 }}>Defensive Totals</Typography>
                                
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {DefensiveStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                {recentSkaterStats}
                <TabPanel value={tabIndex} index={2}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Shooting</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['SHT'])} category={'SHT'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Shooting.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Passing</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['PSS'])} category={'PSS'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Passing.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Handeye</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['HND'])} category={'HND'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Handeye.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={5}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Physicality</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['PHS'])} category={'PHS'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Physicality.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={6}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Defense</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <OverallRatingBar rating={parseInt(playerStats.overall_results.overall_results['Category Ratings']['DEF'])} category={'DEF'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {DefensiveStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={7}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Zone ability</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ZoneAbility.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={8}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Superstar ability</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem' }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {SuperstarAbility.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        );
    } else if (positionState === 'goalie') {
        return (
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="Hockey stats tabs" variant="scrollable">
                        <Tab label="Career Stats" sx={{ color: 'white' }} />
                        <Tab label="Recent Games" sx={{ color: 'white' }} />
                        <Tab label="Xfactor stats" sx={{ color: 'white' }} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Goalie stats</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <Typography sx={{ color: 'white', marginBottom: 3 }}>Games played: {playerStats['Goalie games played']}</Typography>
                                <OverallRatingBar rating={parseInt(playerStats.overall_results.goalie_overall_results['Overall Rating'])} category={'OVR'} />
                                <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 2 }} />
                                <OverallRatingBar rating={parseInt(playerStats.overall_results.goalie_overall_results['Category Ratings']['CON'])} category={'CON'} />
                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieStats3.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 3 }} />
                                <OverallRatingBar rating={parseInt(playerStats.overall_results.goalie_overall_results['Category Ratings']['CLU'])} category={'CLU'} />
                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieStats2.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 3 }} />
                                <OverallRatingBar rating={parseInt(playerStats.overall_results.goalie_overall_results['Category Ratings']['REF'])} category={'REF'} />
                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                {recentGoalieStatsHUH}
                <TabPanel value={tabIndex} index={2}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Xfactor stats</Typography>
                                <Divider sx={{ borderColor: '#424549', marginTop: 1, marginBottom: 2 }} />

                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1, border: 'solid #656565 .001rem', }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieXfactorAbility.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        );
    }
};

export default HockeyDataComponent;
