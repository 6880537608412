import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider, Link } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
import GameCarousel from '../GameCarousel';
import { CircularProgress } from '@mui/material';

function WCA_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [gameData, setGameData] = useState();
    const [gamesLoading, setGamesLoading] = useState(true);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        document.title = `Chel stats | WCA`;
    }, []);

    useEffect(() => {
        const fetchGameData = async () => {
            setGamesLoading(true)
            try {
                const response = await fetch(`/api/gamedata/WCA`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data = await response.json();
                setGameData(data)
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
            } finally {
                setGamesLoading(false)

            }
        };

        fetchGameData();
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/WCA_Logo.png`);

    return (
        <Container>
            <Box
                sx={{
                    minHeight: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {gamesLoading ? (
                    <CircularProgress color="primary" />
                ) : gameData ? (
                    <GameCarousel gamesData={gameData} league={'WCA'} />
                ) : (
                    <Typography color="white">No games available</Typography>
                )}
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '30px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                    <Typography variant="h6" color="white" fontWeight="800" gutterBottom>
                        Welcome to the WCA!
                    </Typography>
                </Box>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h6" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: .2, marginTop: 1 }}>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for WCA!
                </Typography>
                <Button variant="contained" color="primary" href="/WCA/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/WCA/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/WCA/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard
                </Button>
                {/* <Button variant="contained" color="primary" href="/WCA/skater-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Skater Leaderboard Playoffs
                </Button>
                <Button variant="contained" color="primary" href="/WCA/goalie-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard Playoffs
                </Button> */}
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h6" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: 2, marginTop: 2 }}>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph sx={{ marginLeft: 2 }}>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'chicago-blackhawks'} text={'Chicago Blackhawks'} league={'WCA'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'detroit-red-wings'} text={'Detroit Red Wings'} league={'WCA'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'florida-panthers'} text={'Florida Panthers'} league={'WCA'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'ottawa-senators'} text={'Ottawa Senators'} league={'WCA'} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'philadelphia-flyers'} text={'Philadelphia Flyers'} league={'WCA'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'quebec-nordiques'} text={'Quebec Nordiques'} league={'WCA'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'vancouver-canucks'} text={'Vancouver Canucks'} league={'WCA'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'yotes'} text={'Yotes'} league={'WCA'} />

                    </Container>
                </Container>
            </Box>
        </Container>
    );
}



export default WCA_Home;