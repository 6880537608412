import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, LinearProgress, styled } from '@mui/material';

// Styled progress bar
const RatingProgress = styled(LinearProgress)({
    height: '5px',
    borderRadius: '10px',
    '& .MuiLinearProgress-bar': {
        borderRadius: '10px',
        backgroundColor: '#2196f3', // Set your preferred filled color
        transition: 'transform 1s ease-in-out', // Animation for the fill
    },
    '&.MuiLinearProgress-root': {
        backgroundColor: '#B9BABA', // Background color for unfilled part
    },
});

const StatBar = ({ stat, max, statType, min, rank }) => {
    const [progress, setProgress] = useState(0); // State for animation
    const [isInView, setIsInView] = useState(false); // Track if bar is in view
    const progressRef = useRef(null); // Reference to the progress bar

    // Intersection Observer to detect when the StatBar is in view
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting) {
                    setIsInView(true);
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the bar is in view
        );
        if (progressRef.current) {
            observer.observe(progressRef.current);
        }
        return () => {
            if (progressRef.current) {
                observer.unobserve(progressRef.current);
            }
        };
    }, []);

    // Trigger the progress animation when the bar is in view
    useEffect(() => {
        if (isInView) {
            setTimeout(() => {
                let numericStat = stat;

                // Handle percentage values
                if (typeof stat === 'string' && stat.includes('%')) {
                    numericStat = parseFloat(stat.replace('%', '').trim());
                } else if (typeof stat === 'string') {
                    numericStat = parseFloat(stat.trim());
                }

                if (isNaN(numericStat)) {
                    numericStat = 0;
                }

                // Calculate progress for stats using min as the "max"
                let progressValue;
                if (statType === 'Goals Allowed' || statType === 'SOG Allowed' || statType === 'Penalty Minutes') {

                    progressValue = ((max - numericStat) / (max - min)) * 100;
                } else {
                    // Standard scaling: higher is better
                    progressValue = (numericStat / max) * 100;
                }

                // Ensure progress is clamped between 0 and 100
                setProgress(Math.min(Math.max(progressValue, 0), 100));
            }, 100);
        }
    }, [isInView, stat, max, statType]);


    // Format rank with proper suffix
    let formattedRank = rank;
    if (rank == '1') {
        formattedRank = '1st';
    } else if (rank == '2') {
        formattedRank = '2nd';
    } else if (rank == '3') {
        formattedRank = '3rd';
    } else {
        formattedRank = `${rank}th`;
    }

    return (
        <Box sx={{ width: '100%', marginBottom: 5 }} ref={progressRef}>
            <Box position="relative" width="100%" height="20px">
                <Box display="flex" alignItems="center" marginBottom={0.3}>
                    <Typography
                        variant="caption"
                        sx={{
                            color: 'white',
                            fontWeight: '400',
                            fontSize: '14px',
                            textAlign: 'left', // Ensure text alignment to the left
                            flexGrow: 1, // Makes sure it aligns to the full width of the Box
                        }}
                    >
                        {`${statType}`} {/* Display stat out of max */}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{
                            color: 'white',
                            fontWeight: '400',
                            fontSize: '14px',
                            textAlign: 'right',
                            flexGrow: 1,
                        }}
                    >
                        {`${isFinite(stat) ? stat : 0}`}
                        <span style={{ color: '#B0B0B0' }}> ({formattedRank})</span> {/* Make rank grey */}
                    </Typography>
                </Box>
                <RatingProgress
                    variant="determinate"
                    value={progress} // Use progress state for animation
                />
            </Box>
        </Box>
    );
};

export default StatBar;

