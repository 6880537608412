import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function PlayerOptions({ onSave, username }) {
  const [open, setOpen] = useState(false);

  const [settings, setSettings] = useState({
    jersey: '',
    freeAgent: false,  // Initialize as boolean
    position: '',
  });

  const handleOpen = async () => {
    setOpen(true);
    try {
      const response = await fetch(`/api/player/options?username=${username}`);
      if (response.ok) {
        const currentOptions = await response.json();
        setSettings(currentOptions.info.settings || {
          jersey: '',
          freeAgent: false,  // Ensure boolean initialization
          position: '',
        });
      } else {
        console.error("Failed to fetch user options");
      }
    } catch (error) {
      console.error("Error fetching user options:", error);
    }
  };

  const handleClose = () => setOpen(false);

  const handleSaveOptions = async () => {
    // Check if freeAgent is false, and if so, reset the position value
    const updatedSettings = {
      ...settings,
      position: settings.freeAgent ? settings.position : ''  // Reset position if not a free agent
    };

    const data = { settings: updatedSettings };

    try {
      const response = await fetch(`/api/player/options?username=${username}`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      if (response.ok) {
        console.log('User options saved successfully:', responseData);
        onSave(data);  // Pass the updated settings if necessary
      } else {
        console.error('Failed to save user options:', responseData);
      }
    } catch (error) {
      console.error('Error saving user options:', error);
    }

    handleClose();
  };


  const handleSettingsChange = (field, value) => {
    // If handling 'freeAgent', expect value to be boolean directly from e.target.checked
    if (field === 'freeAgent') {
      setSettings(prevSettings => ({
        ...prevSettings,
        [field]: value  // 'value' is already a boolean from e.target.checked
      }));
    } else {
      // For other fields, handle normally
      setSettings(prevSettings => ({
        ...prevSettings,
        [field]: value
      }));
    }
  };

  return (
    <>
      <Button onClick={handleOpen} sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}>Options</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Player Options</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: 2, mb: 2 }}>
            <InputLabel>Jersey Number</InputLabel>
            <Select
              value={settings.jersey}
              label="Jersey Number"
              onChange={(e) => handleSettingsChange('jersey', e.target.value)}
            >
              {[...Array(100).keys()].map(num => (
                <MenuItem key={num} value={num}>{num}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Switch checked={settings.freeAgent} onChange={(e) => handleSettingsChange('freeAgent', e.target.checked)} />}
            label="Free Agent"
          />
          <FormControl fullWidth sx={{ mb: 1, mr: 2, mt: 1 }}>
            <InputLabel>Position</InputLabel>
            <Select
              value={settings.freeAgent ? settings.position : 'Not Applicable'}  // Show 'Not Applicable' when freeAgent is false
              label="Position"
              onChange={(e) => handleSettingsChange('position', e.target.value)}
              disabled={!settings.freeAgent}
            >
              <MenuItem value="Not Applicable" disabled>Not Applicable</MenuItem>
              <MenuItem value="Center">Center</MenuItem>
              <MenuItem value="Left wing">Left wing</MenuItem>
              <MenuItem value="Right wing">Right wing</MenuItem>
              <MenuItem value="Left defense">Left defense</MenuItem>
              <MenuItem value="Right defense">Right defense</MenuItem>
              <MenuItem value="Goalie">Goalie</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handleSaveOptions} sx={{ marginTop: 1, float: 'right' }}>Save</Button>
        </DialogContent>
      </Dialog>
    </>
  );

}

export default PlayerOptions;
