import React from 'react';
import { Box, Modal, Typography, List, ListItem, IconButton, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ManageSearchModal = ({ open, onClose, playerSearches, clubSearches, onDeletePlayer, onDeleteClub }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="manage-search-modal-title"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Box
                sx={{
                    width: 400,
                    bgcolor: '#1f1f1f',
                    color: 'white',
                    borderRadius: 4,
                    p: 3,
                    boxShadow: 24,
                }}
            >
                <Typography id="manage-search-modal-title" variant="h6" sx={{ mb: 2 }}>
                    Manage Searches
                </Typography>
                <Divider sx={{ borderColor: '#424549', mb: 2 }} />

                <Typography variant="body1" sx={{ mb: 1 }}>Player Searches:</Typography>
                <List sx={{ mb: 2 }}>
                    {playerSearches.length > 0 ? (
                        playerSearches.map((search, index) => (
                            <ListItem
                                key={search.key}
                                secondaryAction={
                                    <IconButton edge="end" onClick={() => onDeletePlayer(search.key)}>
                                        <DeleteIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                }
                                sx={{ color: 'white' }}
                            >
                                {search.username} ({search.teamname || "Free Agent"})
                            </ListItem>
                        ))
                    ) : (
                        <Typography sx={{ color: '#9e9e9e', pl: 2 }}>No player searches saved</Typography>
                    )}
                </List>

                <Typography variant="body1" sx={{ mb: 1 }}>Club Searches:</Typography>
                <List>
                    {clubSearches.length > 0 ? (
                        clubSearches.map((search, index) => (
                            <ListItem
                                key={search.key}
                                secondaryAction={
                                    <IconButton edge="end" onClick={() => onDeleteClub(search.key)}>
                                        <DeleteIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                }
                                sx={{ color: 'white' }}
                            >
                                {search.teamname}
                            </ListItem>
                        ))
                    ) : (
                        <Typography sx={{ color: '#9e9e9e', pl: 2 }}>No club searches saved</Typography>
                    )}
                </List>
            </Box>
        </Modal>
    );
};

export default ManageSearchModal;
