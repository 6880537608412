import React, { useEffect, useState, useMemo } from 'react';
import { useLoading } from '../Contexts/LoadingContext';
import { PropagateLoader } from 'react-spinners';
import { Container,Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel } from '@mui/material';
import { trimStart } from 'lodash';

function StandingsPage({ league }) {
    const [sheetData, setSheetData] = useState([]);
    const { loading, setLoading } = useLoading();
    const [sortConfig, setSortConfig] = useState({
        key: 'PTS',
        direction: 'desc'
    });
    const visibleColumns = [
        { key: 'Team', sortable: true },
        { key: 'GP', sortable: true },
        { key: 'W', sortable: true },
        { key: 'L', sortable: true },
        { key: 'OTL', sortable: true },
        { key: 'PTS', sortable: true },
        { key: 'GF', sortable: true },
        { key: 'GA', sortable: true },
        { key: 'PP%', sortable: true },
        { key: 'PK%', sortable: true },
        { key: 'DIFF', sortable: true },
    ];
    const pointsColumnIndex = 6; // Adjust if the Points column index changes in the sheet

    function formatTeamNameForLogo(teamName) {
        // Step 1: Split the team name by spaces, remove any league prefix, trim each part, and filter out empty strings
        const nameParts = teamName
            .split(' ')
            .slice(1) // Remove the prefix like "THL"
            .map(part => part.trim()) // Trim each part
            .filter(part => part); // Filter out any empty strings
    
        // Step 2: Join the remaining parts with hyphens and convert to lowercase
        const formattedName = nameParts.join('-').toLowerCase();
    
        return formattedName;
    }

    function getFormattedTeamName(teamName) {
        // Step 1: Split the team name by spaces, remove the league prefix
        const nameParts = teamName.split(' ').slice(1); // Remove the first part (league prefix)
    
        // Step 2: Capitalize the first letter of each word and lowercase the rest
        const formattedName = nameParts
            .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()) // Capitalize each part
            .join(' '); // Join with spaces
    
        return formattedName;
    }

    useEffect(() => {
        setLoading(true);
        const fetchSheetData = async () => {
            try {
                const response = await fetch(`/api/fetch/standings?league=${league}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSheetData(data.values);
                setLoading(false);

                // Automatically sort on page load
                const initialSortKey = sortConfig.key;
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
                setLoading(false);
            }
        };

        fetchSheetData();
    }, [setLoading, league]);

    const handleSort = (key) => {
        const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
        setSortConfig({ key, direction: isAsc ? 'desc' : 'asc' });
    };

    const sortedData = useMemo(() => {
        const columnIndex = sheetData[0]?.indexOf(sortConfig.key);
        if (columnIndex > -1) {
            return sheetData.slice(1).sort((a, b) => {
                const aValue = isNaN(+a[columnIndex]) ? a[columnIndex] : +a[columnIndex];
                const bValue = isNaN(+b[columnIndex]) ? b[columnIndex] : +b[columnIndex];
                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sheetData.slice(1); // Return the data without the header row if not sorting
    }, [sheetData, sortConfig]);

    if (loading) {
        return <PropagateLoader style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }} color="#3498db" />;
    }

    if (!sheetData.length) return null;

    return (
        <Container sx={{alignItems: 'center', marginTop: '20px' }}>
            <Typography variant="h6" sx={{ m: 'auto', marginTop: 4, color: 'white' }}>Standings</Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: 5 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1C4587' }}>
                            {visibleColumns.map((column, columnIndex) => {
                                const { key, sortable } = column;
                                const isSortedColumn = sortConfig.key === key;
                                return (
                                    <TableCell
                                        key={key}
                                        sx={{
                                            backgroundColor: isSortedColumn ? '#a9c4e8' : '#1C4587', // Light blue highlight for sorted column
                                            color: 'white',
                                            p: '6px',
                                            textAlign: 'left',
                                            whiteSpace: 'nowrap',
                                            borderRight: columnIndex === visibleColumns.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                            position: columnIndex === 0 ? 'sticky' : 'static',
                                            left: columnIndex === 0 ? 0 : 'auto', // Position sticky only on the first column
                                            zIndex: columnIndex === 0 ? 2 : 'auto',
                                        }}
                                    >
                                        {sortable ? (
                                            <TableSortLabel
                                                active={isSortedColumn}
                                                direction={isSortedColumn ? sortConfig.direction : 'desc'}
                                                onClick={() => handleSort(key)}
                                                sx={{ color: 'white' }}
                                            >
                                                {key}
                                            </TableSortLabel>
                                        ) : key}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {visibleColumns.map(({ key }, colIndex) => {
                                    const isSortedColumn = sortConfig.key === key;
                                    const cellValue = row[sheetData[0].indexOf(key)];
                                    return (
                                        <TableCell
                                            key={colIndex}
                                            sx={{
                                                backgroundColor: isSortedColumn ? '#a9c4e8' : colIndex == 0 ? 'white' : 'inherit', // Highlight the sorted column in the body as well
                                                p: '6px',
                                                fontWeight: '500',
                                                borderRight: colIndex === visibleColumns.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                                position: colIndex === 0 ? 'sticky' : 'static',
                                                left: colIndex === 0 ? 0 : 'auto', // Position sticky only on the first column
                                                zIndex: colIndex === 0 ? 2 : 'auto',
                                            }}
                                        >
                                            {/* Display team logo in the 'Team' column */}
                                            {key === 'Team' ? (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <img
                                                        src={require(`./${league.toUpperCase()}/Logos/${formatTeamNameForLogo(cellValue)}.png`)} // Assuming logos are stored in the /logos/ folder
                                                        alt={cellValue}
                                                        style={{ width: '30px', marginRight: '10px' }}
                                                    />
                                                    {getFormattedTeamName(cellValue)}
                                                </Box>
                                            ) : (
                                                cellValue
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default StandingsPage;
