import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';

const ClaimedProfile = ({ username, teamname }) => {
    const navigate = useNavigate();
    const [playerData, setPlayerData] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const dataFetched = useRef(false);

    const handlePlayerClick = (username, teamname, consolegen) => {
        navigate(`/players?username=${username}&teamname=${teamname}&console=common-gen5`);
    };

    const handleClubClick = (teamname) => {
        navigate(`/clubs?teamname=${teamname}&console=common-gen5`);
    };

    const fetchPlayerData = async () => {
        let apiUrl = `/api/players/statsSimple?username=${encodeURIComponent(username)}&teamname=${encodeURIComponent(teamname)}&console=common-gen5`;

        try {
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error('Failed to fetch player data');
            }

            const data = await response.json();
            setPlayerData(data);
        } catch (error) {
            console.error(`Error fetching data for ${username}:`, error);
        } finally {
            setIsLoading(false); // Set loading to false regardless of success or failure
        }
    };

    useEffect(() => {
        if (!dataFetched.current) {
            fetchPlayerData();
            dataFetched.current = true;
        }
    }, []);

    const consolegen = 'common-gen5';

    if (isLoading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                <Typography variant="body1" sx={{ color: 'white' }}>
                    Loading player data...
                </Typography>
            </Box>
        );
    }

    // Render player and club profile with fallback
    return (
        <Box>
            <Box mb={3}>
                <Typography
                    variant="subtitle1"
                    color="#aaaaaa"
                    fontWeight="600"
                    sx={{ textTransform: 'uppercase', letterSpacing: '0.1em', marginBottom: 1 }}
                >
                    Player Profile
                </Typography>

                {/* Display stats if available, otherwise show link */}
                {playerData && playerData.Position ? (
                    <>
                        {['center', 'leftWing', 'rightWing', 'defenseMen'].includes(playerData.Position) && (
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem' }}>
                                    Record: {playerData.Record}
                                </Typography>
                                <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem', marginLeft: 1 }}>
                                    G: {playerData.Goals}
                                </Typography>
                                <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem', marginLeft: 1 }}>
                                    A: {playerData.Assists}
                                </Typography>
                                <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem', marginLeft: 1 }}>
                                    P: {playerData.Points}
                                </Typography>
                            </Box>
                        )}

                        {playerData.Position === 'goalie' && (
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem' }}>
                                    Record: {playerData['Goalie wins']}-{playerData['Goalie losses']}-{playerData['Goalie OTLs']}
                                </Typography>
                                <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem', marginLeft: 1 }}>
                                    GAA: {playerData.GAA}
                                </Typography>
                                <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem', marginLeft: 1 }}>
                                    S%: {playerData['Save %']}
                                </Typography>
                                <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem', marginLeft: 1 }}>
                                    SO: {playerData.Shutouts}
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem' }}>
                        Stats not available for this player.
                    </Typography>
                )}

                <Button
                    endIcon={<ChevronRightIcon />}
                    variant="outlined"
                    color="primary"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '12px 16px',
                        backgroundColor: '#1f1f1f',
                        border: '1px solid #424549',
                        marginTop: 1,
                        borderRadius: '8px',
                        '&:hover': {
                            backgroundColor: '#303030',
                        },
                    }}
                    onClick={() => handlePlayerClick(username, teamname, consolegen)}
                >
                    <Typography sx={{ color: '#ffffff', fontWeight: '600', fontSize: '1rem' }}>{username}</Typography>
                    <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem', marginLeft: 1 }}>
                        ({teamname})
                    </Typography>
                </Button>
            </Box>

            {teamname && (
                <Box>
                    <Typography
                        variant="subtitle1"
                        color="#aaaaaa"
                        fontWeight="600"
                        sx={{ textTransform: 'uppercase', letterSpacing: '0.1em', marginBottom: 1 }}
                    >
                        Club Profile
                    </Typography>
                    {playerData?.clubData?.teamData ? (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem' }}>
                                Record: {playerData.clubData.teamData.record}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography sx={{ color: '#888888', fontWeight: '500', fontSize: '0.875rem' }}>
                            Stats not available for this club.
                        </Typography>
                    )}

                    <Button
                        endIcon={<ChevronRightIcon />}
                        variant="outlined"
                        color="primary"
                        sx={{
                            marginTop: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '12px 16px',
                            backgroundColor: '#1f1f1f',
                            border: '1px solid #424549',
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: '#303030',
                            },
                        }}
                        onClick={() => handleClubClick(teamname)}
                    >
                        <Typography sx={{ color: '#ffffff', fontWeight: '600', fontSize: '1rem' }}>{teamname}</Typography>
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default ClaimedProfile;
