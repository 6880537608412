import React from 'react';
import { Divider, Box, Typography } from '@mui/material';
import StatBarLeagues from './StatBarLeagues';

const TeamStatsDisplay = ({ teamData, maxStats, minStats }) => {
    const offensiveStats = [
        { stat: teamData['GF Per Game'], statType: "Goals", max: maxStats['GF Per Game'], rank: teamData['GF Rank'] },
        { stat: teamData['Shots per game'], statType: "Shots on Goal", max: maxStats['Shots per game'], rank: teamData['Shots Per Game Rank'] },
        { stat: teamData['PP%'], statType: "Power Play (%)", max: maxStats['PP%'], rank: teamData['PP% Rank'] },
        { stat: teamData['Shooting %'], statType: "Shooting (%)", max: maxStats['Shooting %'], rank: teamData['Shooting % Rank'] },
        { stat: teamData['Faceoff %'], statType: "Faceoff (%)", max: maxStats['Faceoff %'], rank: teamData['Faceoff Rank'] }
    ];

    const defensiveStats = [
        { stat: teamData['GA Per Game'], statType: "Goals Allowed", max: maxStats['GA Per Game'], min: minStats['GA Per Game'], rank: teamData['GA Rank'] },
        { stat: teamData['Shots Allowed Per Game'], statType: "SOG Allowed", max: maxStats['Shots Allowed Per Game'],min: minStats['Shots Allowed Per Game'], rank: teamData['Shots Allowed Per Game Rank'] },
        { stat: teamData['PK %'], statType: "Penalty Kill (%)", max: maxStats['PK %'], rank: teamData['PK % Rank'] },
        { stat: teamData['Hits Per Game'], statType: "Hits", max: maxStats['Hits Per Game'], rank: teamData['Hits Per Game Rank'] },
        { stat: teamData['PIMs Per Game'], statType: "Penalty Minutes", max: maxStats['PIMs Per Game'], min: minStats['PIMs Per Game'], rank: teamData['PIMs Per Game Rank'] }
    ];
    if (teamData) {
        return (
            <Box sx={{ width: '90%', margin: '10px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 2, marginBottom: 3 }}>
                    <Typography sx={{ color: 'white' }}>Offensive Stats</Typography>
                    <Typography sx={{ color: '#B0B0B0' }}>(Rank)</Typography>
                </Box>

                {offensiveStats.map((statItem, index) =>
                    statItem.rank !== "#N/A" && (
                        <StatBarLeagues
                            key={index}
                            stat={statItem.stat}
                            statType={statItem.statType}
                            max={statItem.max}
                            min={statItem.min}
                            rank={statItem.rank}
                        />
                    )
                )}

                <Divider sx={{ borderColor: '#424549', marginTop: 6, marginBottom: 5, width: '90%', marginX: 'auto' }} />

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 2, marginBottom: 3 }}>
                    <Typography sx={{ color: 'white' }}>Defensive Stats</Typography>
                    <Typography sx={{ color: '#B0B0B0' }}>(Rank)</Typography>
                </Box>

                {defensiveStats.map((statItem, index) =>
                    statItem.rank !== "#N/A" && (
                        <StatBarLeagues
                            key={index + offensiveStats.length}
                            stat={statItem.stat}
                            statType={statItem.statType}
                            max={statItem.max}
                            min={statItem.min}
                            rank={statItem.rank}
                        />
                    )
                )}
            </Box>
        );
    }
};

export default TeamStatsDisplay;
