import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, TextField, Typography, Alert } from '@mui/material';
import { useUser } from '../Contexts/UserContext';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function AuthButton() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user, setUser } = useUser();
  const [open, setOpen] = useState(false);  // Modal control
  const [isLoginMode, setIsLoginMode] = useState(true);  // Toggle between login and register
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState(''); // Only for registration
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Check login session on mount
    fetch('/api/auth/session', { credentials: 'include' })  // Include credentials to check session
      .then(response => response.json())
      .then(data => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
          setUser(data.user); // Set user data from session
        } else {
          setIsLoggedIn(false);
        }
      })
      .catch(error => console.error('Error checking login session:', error));
  }, [setUser]);

  const handleLogin = async () => {
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
        credentials: 'include', // Include credentials to maintain session
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
        setIsLoggedIn(true);
        setOpen(false);  // Close modal after login
      } else {
        setErrorMessage('Login failed');
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const handleRegister = async () => {
    try {
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, username, password }),
        credentials: 'include',  // Include credentials to maintain session
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
        setIsLoggedIn(true);
        setOpen(false);  // Close modal after registration
      } else {
        const data = await response.json();
        setErrorMessage(data.message);  // Set the error message from the backend
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
      console.error('Registration error:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'include',  // Include credentials to clear session
      });

      if (response.ok) {
        setUser(null);  // Update user state to reflect logout
        setIsLoggedIn(false);  // Reflect that the user is logged out in the UI
      } else {
        setErrorMessage('Logout failed');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <Button
          fullWidth
          variant="outlined"
          sx={{ color: 'white' }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      ) : (
        <Button
          fullWidth
          variant="outlined"
          sx={{ color: 'white' }}
          onClick={() => setOpen(true)}
        >
          {isLoginMode ? 'Login' : 'Register'}
        </Button>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isLoginMode ? 'Login' : 'Register'}
          </Typography>
          <Box>
            {!isLoginMode && (
              <TextField
                fullWidth
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
              />
            )}
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
            />
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              onClick={isLoginMode ? handleLogin : handleRegister}
            >
              {isLoginMode ? 'Login' : 'Register'}
            </Button>
            <Button
              fullWidth
              variant="text"
              sx={{ mt: 1 }}
              onClick={() => setIsLoginMode(!isLoginMode)}
            >
              {isLoginMode ? "Don't have an account? Register" : "Already have an account? Login"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default AuthButton;
